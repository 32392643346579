/*------------------------------------*\
    Page Template: Assistenza
    assistenza.php
    .page-template-assistenza
\*------------------------------------*/

.page-template-assistenza {
    .main {
        overflow-x: hidden;
        overflow-y: clip;
    }

    .assistenza__header {
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: calc(100vh - var(--h-header));
        padding: $gutter * 1.5 $gutter * 1.5;

        @media #{$media-sm} {
            padding: $gutter;
            min-height: 0;
        }

        @media #{$media-sx} {
            padding: $gutter / 2;
        }

        .col-1 {
            width: 100%;

            h1 {
                font-size: 2.6rem;

                @media #{$media-bx} {
                    font-size: 2.2rem;
                }

                @media #{$media-sx} {
                    font-size: 1.8rem;
                }
            }

            h2 {
                font-size: 4rem;
                font-weight: 700;
                line-height: 1.3;
                margin-top: $gutter / 2;
                margin-bottom: $gutter * 1.5;

                @media #{$media-bx} {
                    font-size: 3.2rem;
                    margin-top: $gutter / 4;
                    margin-bottom: $gutter / 2;
                }

                @media #{$media-sx} {
                    font-size: 2.3rem;
                    line-height: 1.4;
                }
            }

            p {
                font-size: 2rem;
                font-family: $f-family-menu;
                margin-bottom: $gutter / 2;
                max-width: 80%;

                @media #{$media-m} {
                    font-size: 1.8rem;
                }

                @media #{$media-s} {
                    max-width: none;
                }

                @media #{$media-sx} {
                    font-size: 1.6rem;
                }
            }
        }

        //.col-1

        .col-2 {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 40px;

            @media #{$media-m} {
                width: 100%;
                align-items: flex-start;
            }
            
            @media #{$media-s} {
                flex-direction: column;
            }

            .seleziona_tipo_assistenza {
                width: 100%;
                display: flex;
                justify-content: center;
                margin-top: $gutter;
    
                @media #{$media-bx} {
                    margin-top: $gutter / 2;
                }
    
                .tipoAssistenzaForm {
                    position: relative;
    
                    .gfield {
                        position: relative;
                    }
    
                    .gfield_label {
                        font-size: 2.2rem;
                        font-weight: 700;
                    }
    
                    .gfield--type-email {
                        display: none;
                        pointer-events: none;
                    }
    
                    select {
                        background: var(--c-white) url('../images/icon_arrow_down.svg') no-repeat right 20px center / 20px 10px;
                        border: 1px solid var(--c-black);
                        border-radius: 40px;
                        padding: $gutter / 3 3rem;
                        min-width: 358px;
                        appearance: none;
                        line-height: 1;

                        @media #{$media-sx} {
                            min-width: 0;
                        }
    
                        &::placeholder {
                            font-size: 1.4rem;
                            font-style: italic;
                            color: var(--c-corporate-2);
                        }
                    }
    
                    .gfield_validation_message {
                        display: none;
                    }
    
                    .gform_footer {
                        display: none;
                    }
                }
    
            }
    
            // .product_search

            figure {
                justify-content: center;

                picture {
                    width: 100%;
                    align-self: flex-start;

                    @media #{$media-m} {
                        width: 70%;
                    }

                    @media #{$media-sx} {
                        width: 100%;
                    }
                }
            }
        }

        //.col-2
    }
    // .assistenza__header

    .assistenza__blocks {
        .content_block {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            margin-bottom: $gutter * 3;

            @media #{$media-m} {
                margin-bottom: $gutter * 2;
            }

            @media #{$media-sx} {
                margin-top: $gutter / 2;
                margin-bottom: $gutter;
            }

            &.left {
                .col-1 {
                    width: 35%;

                    @media #{$media-sm} {
                        width: 100%;
                    }

                    figure {
                        margin-left: -18%;
                        position: relative;

                        @media #{$media-m} {
                            margin-left: -85%;
                        }

                        @media #{$media-sm} {
                            margin-left: 0%;
                            max-width: 70%;
                        }

                        @media #{$media-sx} {
                            margin-left: 0;
                            margin-bottom: $gutter / 4;
                            max-width: 95%;
                        }

                        &::after {
                            content: '';
                            position: absolute;
                            top: - $gutter / 4;
                            right: -1px;
                            width: 40%;
                            height: 50%;
                            clip-path: polygon(0 50%, 100% 100%, 100% 0);
                            background-color: var(--c-bg);
                        }
                    }
                }
        
                .col-2 {
                    width: 65%;
                    padding-left: $gutter * 3;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    @media #{$media-m} {
                        padding-left: $gutter * 2;
                    }

                    @media #{$media-sm} {
                        padding-left: $gutter;
                        width: 100%;
                    }

                    @media #{$media-sx} {
                        padding: 0 $gutter / 2;
                    }
                }   
            }

            &.right {
                .col-1 {
                    width: 65%;
                    padding-right: $gutter * 3;
                    text-align: right;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    justify-content: center;

                    @media #{$media-sm} {
                        padding-right: $gutter;
                        width: 100%;
                    }

                    @media #{$media-sx} {
                        padding: 0 $gutter / 2;
                        align-items: flex-start;
                        text-align: left;
                    }

                    a.btn {
                        align-self: flex-end;

                        @media #{$media-sx} {
                            align-self: center;
                        }
                    }
                }
        
                .col-2 {
                    width: 35%;

                    @media #{$media-sm} {
                        width: 100%;
                        order: -1;
                        display: flex;
                        justify-content: flex-end;
                    }

                    figure {
                        margin-right: -45%;
                        position: relative;

                        @media #{$media-m} {
                            margin-right: -85%;
                        }

                        @media #{$media-sm} {
                            margin-right: 0%;
                            max-width: 70%;
                        }

                        @media #{$media-sx} {
                            margin-right: 0;
                            margin-bottom: $gutter / 4;
                            max-width: 95%;
                        }

                        &::after {
                            content: '';
                            position: absolute;
                            top: - $gutter / 4;
                            left: -1px;
                            width: 40%;
                            height: 50%;
                            clip-path: polygon(0 100%, 100% 50%, 0 0);
                            background-color: var(--c-bg);
                        }
                    }
                }   
            }

            h3 {
                font-size: 2rem;
                margin-top: $gutter / 2;
                margin-bottom: $gutter / 2;

                @media #{$media-b} {
                    font-size: 1.8rem;
                }

                @media #{$media-sx} {
                    font-size: 1.6rem;
                }
            }

            h4 {
                font-size: 5rem;
                font-weight: 700;
                margin-bottom: $gutter / 2;

                @media #{$media-b} {
                    font-size: 2.6rem;
                }

                @media #{$media-sx} {
                    font-size: 2.3rem;
                }
                
            }

            p {
                font-family: $f-family-menu;
                font-size: 2rem;
                margin-bottom: $gutter / 2;
                max-width: 70%;

                @media #{$media-bx} {
                    max-width: 90%;
                }

                @media #{$media-b} {
                    font-size: 1.8rem;
                }

                @media #{$media-sx} {
                    font-size: 1.6rem;
                    max-width: none;
                }
            }

            a.btn {
                margin-top: $gutter;
                align-self: flex-start;

                @media #{$media-sx} {
                    align-self: center;
                    margin-top: $gutter / 2;
                }
            } 
        }
    }
    //.assistenza__blocks
}

// .page-template-assistenza