/*------------------------------------*\
    Page Template: Servizi
    servizi.php
    .page-template-servizi
\*------------------------------------*/

.page-template-servizi {
    .main {
        overflow-x: hidden;
        overflow-y: clip;
    }

    .servizi__header {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        min-height: calc(100vh - var(--h-header));
        padding: $gutter * 1.5 $gutter * 1.5;

        @media #{$media-sm} {
            padding: $gutter;
        }

        @media #{$media-sx} {
            padding: $gutter / 2;
        }

        .col-1 {
            width: 50%;

            @media #{$media-m} {
                width: 100%;
            }

            h1 {
                font-size: 2.6rem;

                @media #{$media-bx} {
                    font-size: 2.2rem;
                }

                @media #{$media-sx} {
                    font-size: 1.8rem;
                }
            }

            h2 {
                font-size: 4rem;
                font-weight: 700;
                line-height: 1.3;
                margin-top: $gutter / 2;
                margin-bottom: $gutter * 1.5;

                @media #{$media-bx} {
                    font-size: 3.2rem;
                    margin-top: $gutter / 4;
                    margin-bottom: $gutter / 2;
                }

                @media #{$media-sx} {
                    font-size: 2.3rem;
                    line-height: 1.4;
                }
            }

            p {
                font-size: 2rem;
                font-family: $f-family-menu;
                line-height: 1.4;
                margin-bottom: $gutter / 2;

                @media #{$media-m} {
                    font-size: 1.8rem;
                }

                @media #{$media-sx} {
                    font-size: 1.6rem;
                }
            }
        }

        //.col-1

        .col-2 {
            width: 50%;
            display: flex;

            @media #{$media-m} {
                width: 100%;
                align-items: flex-start;
            }
            
            @media #{$media-s} {
                flex-direction: column;
            }

            figure {
                width: 100%;

                @media #{$media-b} {
                    justify-content: center;
                }

                @media #{$media-m} {
                    width: 80%;
                }

                @media #{$media-sm} {
                    margin-top: $gutter;
                }

                @media #{$media-s} {
                    width: 100%;
                }

                @media #{$media-sx} {
                    display: none;
                }

                picture {
                    width: 100%;
                    align-self: flex-start;

                    @media #{$media-m} {
                        width: 70%;
                    }
                }
            }
        }

        //.col-2


        .voci {
            display: flex;
            flex-wrap: wrap;
            margin-top: $gutter;

            @media #{$media-sm} {
                margin-top: 0;
            }

            &.desktop {
                @media #{$media-m} {
                    display: none;
                }
            }

            &.mobile {
                display: none;

                @media #{$media-m} {
                    display: flex;
                    width: 100%;
                }
            }

            .voce {
                width: 50%;
                position: relative;
                display: flex;
                align-items: center;
                padding: $gutter / 2 0 $gutter / 2 $gutter;
                font-size: 2.2rem;
                font-weight: 700;
                background: url('../images/icon_v_alternative.svg') no-repeat center left / 30px 30px;
                border-bottom: 1px solid var(--c-grey-dark);

                @media #{$media-m} {
                    gap: 20px;
                }

                @media #{$media-sm} {
                    gap: 0;
                    width: 100%;
                }

                @media #{$media-sx} {
                    width: 100%;
                    font-size: 1.8rem;

                    &:last-of-type {
                        border-bottom: 0;
                    }
                }
            }

        }
        // .voci
    }
    // .servizi__header

    .servizi__blocks {
        .content_block {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            margin-bottom: $gutter * 3;

            @media #{$media-m} {
                margin-bottom: $gutter * 2;
            }

            @media #{$media-sx} {
                margin-top: $gutter / 2;
                margin-bottom: $gutter;
            }

            &.left {
                .col-1 {
                    width: 30%;

                    @media #{$media-sm} {
                        width: 100%;
                    }

                    figure {
                        margin-left: -45%;

                        @media #{$media-m} {
                            margin-left: -85%;
                        }

                        @media #{$media-sm} {
                            margin-left: 0%;
                            max-width: 70%;
                        }

                        @media #{$media-sx} {
                            margin-left: -40%;
                            margin-bottom: $gutter / 4;
                            max-width: none;
                        }
                    }
                }
        
                .col-2 {
                    width: 70%;
                    padding-left: $gutter * 3;

                    @media #{$media-m} {
                        padding-left: $gutter * 2;
                    }

                    @media #{$media-sm} {
                        padding-left: $gutter;
                        width: 100%;
                    }
                }   
            }

            &.right {
                .col-1 {
                    width: 70%;
                    padding-left: 10%;
                    padding-top: $gutter;

                    @media #{$media-sm} {
                        width: 100%;
                    }

                    @media #{$media-m} {
                        padding-top: 0;
                        padding-left: 8%;
                    }
                }
        
                .col-2 {
                    width: 30%;

                    @media #{$media-sm} {
                        width: 100%;
                        order: -1;
                        display: flex;
                        justify-content: flex-end;
                    }

                    figure {
                        margin-right: -45%;

                        @media #{$media-m} {
                            margin-right: -85%;
                        }

                        @media #{$media-sm} {
                            margin-right: 0%;
                            max-width: 70%;
                        }

                        @media #{$media-sx} {
                            margin-right: -40%;
                            margin-bottom: $gutter / 4;
                            max-width: none;
                        }
                    }
                }   
            }

            h3 {
                font-size: 2rem;
                margin-top: $gutter / 2;
                margin-bottom: $gutter / 2;

                @media #{$media-b} {
                    font-size: 1.8rem;
                }

                @media #{$media-sx} {
                    font-size: 1.6rem;
                }
            }

            h4 {
                font-size: 3.2rem;
                font-weight: 700;
                margin-bottom: $gutter / 2;

                @media #{$media-b} {
                    font-size: 2.6rem;
                }

                @media #{$media-sx} {
                    font-size: 2.3rem;
                }
                
            }

            p {
                font-family: $f-family-menu;
                font-size: 2rem;
                margin-bottom: $gutter / 2;
                max-width: 70%;

                @media #{$media-bx} {
                    max-width: 90%;
                }

                @media #{$media-m} {
                    font-size: 1.8rem;
                }

                @media #{$media-sx} {
                    font-size: 1.6rem;
                }
            }
        }
    }
    //.servizi__blocks
}

// .page-template-servizi