/*------------------------------------*\
   Slick custom styles
\*------------------------------------*/

.slick-dots {
    display: flex;
    justify-content: center;
    gap: 10px;
    z-index: 10;
    position: relative;
    margin-top: $gutter / 2;

    li {
        button {
            color: transparent;
            border-radius: 50%;
            background-color: var(--c-white);
            border: 2px solid var(--c-corporate-5);
            outline: none;
            width: 20px;
            height: 20px;
        }

        &.slick-active {
            button {
                background-color: var(--c-corporate-5);
            }
        }
    }
}