/*------------------------------------*\
    Page Template: Wishlist
    wishlist.php
    .page-template-wishlist
\*------------------------------------*/

.page-template-wishlist {

    .wishlist__header {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        padding: $gutter * 1.5 $gutter * 1.5 $gutter $gutter * 1.5;

        @media #{$media-m} {
            padding-top: $gutter / 2;
        }

        @media #{$media-sm} {
            padding: $gutter;
        }

        @media #{$media-sx} {
            padding: $gutter / 2;
        }

        .col-1 {
            width: 100%;
            display: flex;
            flex-direction: column;

            h1 {
                font-size: 2.6rem;

                @media #{$media-bx} {
                    font-size: 2.2rem;
                }

                @media #{$media-sx} {
                    font-size: 1.8rem;
                }
            }

            h2 {
                font-size: 4rem;
                font-weight: 700;
                line-height: 1.3;
                margin-top: $gutter / 2;
                margin-bottom: $gutter * 1.5;

                @media #{$media-bx} {
                    font-size: 3.2rem;
                    margin-top: $gutter / 4;
                    margin-bottom: $gutter;
                }

                @media #{$media-sx} {
                    font-size: 2.3rem;
                    line-height: 1.4;
                    margin-bottom: $gutter / 4;
                }
            }

            p {
                font-size: 2rem;
                font-family: $f-family-menu;
                margin-bottom: $gutter / 2;
                max-width: 50%;

                @media #{$media-bx} {
                    font-size: 1.8rem;
                }

                @media #{$media-b} {
                    max-width: 60%;
                }

                @media #{$media-m} {
                    max-width: 70%;
                }

                @media #{$media-sm} {
                    max-width: none;
                }

                @media #{$media-sx} {
                    font-size: 1.6rem;
                }
            }
        }

        //.col-1

    }
    // .wishlist__header

    .wishlist__elenco_auto {
        display: flex;
        flex-direction: column;

        .elenco_auto {
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            grid-template-rows: 1fr;
            grid-column-gap: 50px;
            grid-row-gap: 60px;
            padding: 0 5rem;

            @media #{$media-u} {
                grid-template-columns: repeat(4, 1fr);
                grid-column-gap: 20px;
            }

            @media #{$media-bx} {
                grid-template-columns: repeat(3, 1fr);
                grid-column-gap: 0;
            }

            @media #{$media-m} {
                padding: 0 3rem;
                grid-template-columns: repeat(2, 1fr);
            }

            @media #{$media-sm} {
                padding: 0 4rem;
            }

            @media #{$media-s} {
                grid-template-columns: repeat(1, 1fr);
                padding: 0;
                margin-top: $gutter / 2;
            }

            @media #{$media-s} {
                padding: 0 $gutter / 2;
                grid-row-gap: 20px;
            }

            .box_auto_elenco {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;

                .image {
                    width: 320px;
                    height: 240px;
                    position: relative;

                    @media #{$media-sm} {
                        width: 280px;
                        height: 280px;
                    }

                    @media #{$media-s} {
                        width: 320px;
                        height: 320px;
                    }

                    @media #{$media-sx} {
                        width: 100%;
                        height: auto;
                    }

                    figure {
                        width: 100%;
                        height: 100%;
                    }

                    .removeFromWishlist {
                        position: absolute;
                        right: 1rem;
                        bottom: 0rem;
                        transition: $trans-default;

                        @media #{$media-sx} {
                            width: 55px;
                            height: 55px;
                        }

                        &:hover {
                            transform: scale(1.1);
                        }
                    }
                }

                .content {
                    padding: $gutter / 2 0;

                    @media #{$media-sx} {
                        padding: $gutter / 4;
                    }

                    h6 {
                        text-transform: none;
                        font-size: 2.4rem;
                        margin-top: 0;
                        min-height: $gutter * 1.5;
                    }

                    .box_prezzo {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        gap: 15px;
                    }

                    .box_caratteristiche {
                        justify-content: space-around;

                        @media #{$media-sx} {
                            margin-top: $gutter / 2;
                        }
                    }

                    a {
                        text-decoration: none;
                        justify-content: center;
                        display: inline-flex;
                        color: var(--c-white);

                        @media #{$media-sx} {
                            margin-top: $gutter / 2;
                        }
                    }

                    .inseritaIl {
                        display: block;
                        margin-top: $gutter / 2;
                        font-size: 1.4rem;
                        font-family: $f-family-menu;
                    }
                }
            }
        }

        // .elenco_auto

        .wishlistEmpty, .viaAjax{
            display: flex;
            justify-content: center;
            width: 100%;
            padding: 0 $gutter * 1.5 $gutter * 2 $gutter * 1.5;

            @media #{$media-sm} {
                padding: $gutter;
            }

            @media #{$media-sx} {
                padding: $gutter / 2;
            }

            .title {
                background-color: var(--c-oil);
                color: var(--c-black);
                font-family: $f-family-menu;
                padding: $gutter / 8 $gutter / 4;
            }
        }

        // .wishlistEmpty, .viaAjax

        .viaAjax {
            display: none;

            &.visible {
                display: flex;
            }
        }
        
    }
    // .wishlist__elenco_auto
}

// .page-template-wishlist