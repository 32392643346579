/*------------------------------------*\
    Form ( use Gravity Forms )
    Style write by Gravity Form, to force use !important
   .gform_wrapper
\*------------------------------------*/

.gform_validation_errors {
    display: none;
}

.gform_wrapper {

    // :focus { outline: .1rem dashed var(--c-brand-accent); outline-offset: .2rem; }

    // color: var(--c-black);



    // Placehlder
    ::placeholder { color: var(--c-black); }



    // Confirmation Message
    .gform_confirmation_message {}



    // Validation
    .gform_validation_errors {}// .gform_validation_errors

    .validation_error,
    .validation_message {}



    // Required
    .gfield_required { color: currentColor !important; }
    .gform_required_legend { display: none; }



    // Error

    .gfield.gfield_error {
        background-color: transparent !important;
        border-width: 0 !important;
        padding: 0 !important;
    }

    .gfield_error label,
    .gfield_error legend {
        color: var(--c-error) !important;
    }

    .gfield_error input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]),
    .gfield_error textarea,
    .gfield_error select,
    .gfield_error [aria-invalid=true] {
        border-color: var(--c-error);
    }

    .gfield_error div.ginput_complex.ginput_container label,
    .gfield_error ul.gfield_checkbox,
    .gfield_error ul.gfield_radio {
        border-color: var(--c-error);
    }



    // Heading
    .gform_heading {

        .gform_title {}// .gform_title

        .gform_description {}// .gform_description

    }// .gform_heading



    // General
    .gfield {

        margin-top: 2rem;

        &.titoloForm {
            font-size: 3rem;
            font-weight: 700;

            @media #{$media-s} { font-size: 2.6rem; }
            @media #{$media-sx} { font-size: 2.2rem; }
        }

    }// .gfield

    &.gravity-theme {
        .gfield {
            margin-top: 1rem;

            @media #{$media-sx} { margin-top: 0; }
        }

        .gform-body {
            .gfield_label {

                font-size: 1.8rem;
                font-family: $f-family-menu;
                font-weight: 400;
                margin-bottom: $gutter / 4;
        
                @media #{$media-s} {
                    font-size: 1.6rem;
                }
            }
        }
    }

    input[type=text],
    input[type=email],
    input[type=tel],
    input[type=password],
    input[type=file],
    textarea,
    select {

        color: var(--c-corporate-1);
        background-color: var(--c-white);
        border: 2px solid var(--c-grey-dark);
        border-radius: 10px;
        padding: $gutter / 3 3rem;
        width: 100% !important;
        line-height: 2;

    }

    input[type=file] {
        padding: 0.75rem 3rem;
    }

    select { 
        height: 5rem;
        background: var(--c-white) url('../images/icon_arrow_down.svg') no-repeat right 20px center / 20px 10px;
        appearance: none;
    }

   .ginput_complex span {
        @media #{$media-sx} {
            margin-bottom: 0;
        }
   }



    // Container
    //.ginput_container { margin-top: 0 !important; }


    // Privacy checkbox
    // use gravity consent
    .ginput_container_consent, .ginput_container_radio {

        display: flex;
        align-items: center;

        input[type=checkbox], input[type=radio] {

            flex-shrink: 0;
            width: 3rem !important;
            height: 3rem;
            margin-top: 0 !important;
            margin-right: 2rem;
            vertical-align: middle;
            accent-color: var(--c-accent);

        }

        .gfield_consent_label {

            line-height: 1.4;
            font-size: 1.6rem;
            font-weight: $f-weight-300;

            a {
                color: currentColor;
                border-bottom: .1rem solid currentColor;
            }

            @media #{$media-s} {
                font-size: 1.4rem;

                @media #{$media-sx} { font-size: 1.2rem; }
            }

        }// .gfield_consent_label

    }
    .gfield_error .ginput_container_consent {

        .gfield_consent_label {
            color: var(--c-error);
        }

    }

    .gfield_html {
        @media #{$media-sx} { font-size: 1.4rem; }
    }


    // Footer
    .gform_footer {

        margin: 4rem auto 0;
        padding: 0;
        display: flex;
        justify-content: center;

    }



    // Submit
    // input[type=submit] covented in tag button in extras.php
    .gform_footer button,
    .gform_footer input[type=submit] {

        margin-top: 4rem;
        border-radius: 25px;
        padding: 1.5rem 6rem 1.2rem 5rem;
        line-height: 1.4;
        display: inline-flex;
        align-items: center;
        transition: $trans-default;
        background-color: var(--c-accent);
        color: var(--c-grey-light);
        font-size: 1.8rem;
        text-transform: uppercase;
        outline: none;
        border: 0;
        position: relative;
        display: flex;
        align-items: center;

        @media #{$media-sx} {
            margin-top: $gutter / 4;
            font-size: 1.6rem;
        }

        &::before {
            content: '';
            position: absolute;
            right: 2rem;
            background: url('../images/icon_arrow.svg') no-repeat center;
            width: 29px;
            height: 24px;
        }

        &:hover {
            background-color: var(--c-corporate-1);
        }

    }



}// .form
