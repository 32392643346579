/*------------------------------------*\
    Page Template: Single model
    single-model.php
    .single-model
\*------------------------------------*/

.single-model {
    .main {
        overflow-x: hidden;
        overflow-y: clip;
    }

    .v_bg {
        width: 35vw;
        height: calc(100vh - var(--h-header));
        min-width: 560px;
        position: absolute;
        top: 0;
        pointer-events: none;
        z-index: -1;
        overflow: hidden;
        display: flex;

        svg {
            path {
                fill: var(--c-white);
            }
        }
    }

    .icons_callme {
        position: fixed;
        display: flex;
        bottom: $gutter / 2;
        right: $gutter / 2;
        gap: 20px;
        z-index: 10;

        .item {
            border-radius: 50%;
            background-color: var(--c-corporate-5);
            width: 50px;
            height: 50px;
            transition: $trans-default;

            @media #{$media-bx} {
                width: 40px;
                height: 40px;
            }

            &:hover {
                background-color: var(--c-black);
                transform: scale(1.05);
            }

            a {
                line-height: 1;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                @media #{$media-bx} {
                    padding: $gutter / 6;
                }
            }
        }
    }

    .model__header {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        min-height: calc(100vh - var(--h-header));
        padding: $gutter * 1.5 $gutter * 1.5;

        @media #{$media-sm} {
            padding: $gutter;
        }

        @media #{$media-sx} {
            padding: $gutter / 2;
        }

        .col-1 {
            width: 25%;

            @media #{$media-b} {
                width: 100%;
            }

            .top {
                @media #{$media-sx} {
                    text-align: center;
                }

                h1 {
                    font-size: 2.6rem;

                    @media #{$media-bx} {
                        font-size: 2.2rem;
                    }

                    @media #{$media-sx} {
                        font-size: 1.8rem;
                    }
                }

                h2 {
                    font-size: 4rem;
                    font-weight: 700;
                    line-height: 1.3;
                    margin-top: $gutter / 2;
                    margin-bottom: $gutter;
                    width: 30vw;
                    max-width: 600px;

                    @media #{$media-bx} {
                        font-size: 3.2rem;
                        margin-top: $gutter / 4;
                        margin-bottom: $gutter / 2;
                    }

                    @media #{$media-b} {
                        width: 100%;
                        max-width: none;
                    }

                    @media #{$media-sx} {
                        font-size: 2.3rem;
                        line-height: 1.4;
                    }
                }

                p {
                    font-size: 2.4rem;
                    line-height: 1.4;

                    @media #{$media-bx} {
                        font-size: 2rem;
                    }

                    @media #{$media-sx} {
                        font-size: 1.8rem;
                    }
                }
            }
            //.top

            .bottom {
                display: flex;
                align-items: center;
                gap: 20px;
                margin-top: 23vh;

                @media #{$media-b} {
                    display: none;
                }

                h4 {
                    font-size: 2.6rem;

                    @media #{$media-bx} {
                        font-size: 1.8rem;
                    }
                }

                h5 {
                    font-family: $f-family-menu;
                    font-size: 2.4rem;
                    font-weight: 900;
                    color: var(--c-corporate-5);

                    @media #{$media-bx} {
                        font-size: 2rem;
                    }
                }
            }
            //.bottom
        }
        //.col-1

        .col-2 {
            width: 50%;
            //height: 70vh;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            padding-top: $gutter * 3;

            @media #{$media-bx} {
                padding-top: $gutter * 2;
            }

            @media #{$media-b} {
                padding-top: 0;
                width: 60%;
            }

            @media #{$media-sm} {
                width: 100%;
            }

            .content {
                display: flex;
                flex-direction: column;
                align-items: center;

                .vediGallery {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    text-decoration: underline;
                    font-size: 1.6rem;
                    margin: $gutter 0;
                    transition: $trans-default;

                    @media #{$media-bx} {
                        font-size: 1.4rem;
                    }

                    @media #{$media-b} {
                        margin: $gutter / 2 0;
                    }
    
                    svg {
                        @media #{$media-b} {
                            width: 25px;
                        }

                        path {
                            transition: $trans-default; 
                        }
                    }
    
                    &:hover {
                        color: var(--c-accent);
                        transform: scale(1.05);
                        
                        svg {
                            path {
                                fill: var(--c-accent);
                            }
                        }
                    }
                }

                .features_mobile {
                    display: none;
                    flex-direction: column;
                    justify-content: center;
                    width: 100%;
                    margin-bottom: $gutter / 2;

                    @media #{$media-sm} {
                        display: flex;
                    }

                    h4 {
                        font-size: 2rem;
                        font-weight: 700;
                        margin-bottom: $gutter / 4;
        
                        @media #{$media-sx} {
                            font-size: 1.6rem;
                        }
                    }
        
                    ul {
                        list-style: circle;
                        font-size: 1.5rem;
                        margin-left: $gutter / 2;

                        @media #{$media-sx} {
                            margin-bottom: $gutter / 4;
                        }
     
                        li {
                            margin-bottom: $gutter / 4;

                            @media #{$media-sx} {
                                margin-bottom: $gutter / 8;
                            }
                        }
                    }
                }
    
                .buttons {
                    display: flex;
                    gap: 20px;
                    flex-wrap: wrap;
                    justify-content: center;

                    @media #{$media-bx} {
                        gap: 10px;
                    }

                    @media #{$media-sx} {
                        margin-bottom: $gutter / 2;
                    }
    
                    .btn {
                        text-transform: uppercase;

                        @media #{$media-bx} {
                            font-size: 1.3rem;
                            padding: 1.5rem 2rem 1.2rem;
                        }

                        @media #{$media-sx} {
                            flex: 1 0 auto;
                            justify-content: center;
                        }
                    }
                }

                .prezzoAPartire_mobile {
                    display: none;
                    align-items: center;
                    gap: 20px;
                    margin-bottom: $gutter;
    
                    @media #{$media-b} {
                        display: flex;
                    }

                    @media #{$media-sm} {
                        margin-bottom: $gutter / 2;
                    }
    
                    h4 {
                        font-size: 1.8rem;
                        margin-bottom: 0;
    
                        @media #{$media-sx} {
                            font-size: 1.6rem;
                        }
                    }
    
                    h5 {
                        font-family: $f-family-menu;
                        font-size: 2rem;
                        font-weight: 900;
                        color: var(--c-corporate-5);
    
                        @media #{$media-sx} {
                            font-size: 1.8rem;
                        }
                    }
                }
            }
        }
        //.col-2

        .col-3 {
            width: 25%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: $gutter;

            @media #{$media-b} {
                width: 40%;
                justify-content: flex-start;
            }

            @media #{$media-sm} {
                width: 100%;
                margin-top: $gutter;
                display: none;
            }

            @media #{$media-s} {
                padding-left: 0;
            }

            h4 {
                font-size: 2.2rem;
                font-weight: 700;
                margin-bottom: $gutter / 4;

                @media #{$media-bx} {
                    font-size: 2rem;
                }
            }

            ul {
                list-style: circle;
                font-size: 1.8rem;
                margin-left: $gutter / 2;

                @media #{$media-bx} {
                    font-size: 1.5rem;
                }

                li {
                    margin-bottom: $gutter / 4;
                }
            }
        }
        //.col-3
    }
    // .model__header

    .model__slider_features {

        .sliderCaratteristiche {

            .item {
                .box_caratteristica {
                    display: flex;
                    flex-wrap: wrap;
                    position: relative;

                    @media #{$media-m} {
                        padding-bottom: $gutter * 3;
                    }

                    .arrows_messages {
                        position: absolute;
                        bottom: 4rem;
                        width: calc(100% - 8rem);
                        display: flex;
                        justify-content: space-between;

                        @media #{$media-b} {
                            font-size: 1.4rem;
                        }

                        @media #{$media-m} {
                            display: none;
                        }

                        &.mobile {
                            display: none;

                            @media #{$media-m} {
                                display: flex;
                                width: 96%;
                                margin: 4% 2%;
                                position: absolute;
                                bottom: 0;
                            }

                            @media #{$media-sx} {
                                padding: 0 2rem;
                                margin-bottom: 3rem;
                            }
                        }
                    }

                    .col-1 {
                        width: 40%;
                        background-color: var(--c-corporate-1);
                        color: var(--c-corporate-3);
                        padding: $gutter * 2 $gutter $gutter $gutter;
                        position: relative;

                        @media #{$media-b} {
                            padding: $gutter;
                        }

                        @media #{$media-m} {
                            width: 100%;
                        }

                        @media #{$media-sx} {
                            padding: $gutter / 1.5;
                        }

                        h1 {
                            font-size: 3.2rem;
                            font-weight: 700;

                            @media #{$media-bx} {
                                font-size: 2.8rem;
                            }

                            @media #{$media-b} {
                                font-size: 2.6rem;
                            }

                            @media #{$media-sm} {
                                font-size: 2.2rem;
                            }

                            @media #{$media-sx} {
                                font-size: 2rem;
                            }
                        }

                        p {
                            font-size: 2.2rem;
                            padding: $gutter 0 $gutter / 2 $gutter / 2;

                            @media #{$media-bx} {
                                font-size: 1.8rem;
                            }

                            @media #{$media-b} {
                                font-size: 1.6rem;
                            }

                            @media #{$media-m} {
                                margin-bottom: 0;
                            }

                            @media #{$media-sm} {
                                padding: $gutter / 2 0 0;
                                font-size: 1.5rem;
                            }
    
                        }
                    }

                    .col-2 {
                        width: 60%;

                        @media #{$media-m} {
                            width: 100%;
                            color: var(--c-corporate-3);
                        }

                        picture {
                            width: 100%;
                        }
                    }
                }
            }
            // .item

            .slick-slide {
                height: 100%;
            }

            .slick-track {
                @media #{$media-m} {
                    background-color: var(--c-corporate-1);
                }
            }

            .slick-arrow {
                position: absolute;
                bottom: $gutter;
                z-index: 2;
                color: transparent;
                border-radius: 20px;
                background-color: transparent;
                background-image: url('../images/icon_arrow.svg');
                background-repeat: no-repeat;
                background-size: 50%;
                border: 0;
                outline: none;
                width: 70px;
                background-position: center;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: $trans-default;

                &.pp2 {
                    transform: rotate(180deg);
                    left: - $gutter / 2;
                    pointer-events: none;
                    opacity: 0.2;
                    filter: grayscale(1);

                    @media #{$media-sx} {
                        left: 0;
                    }
                }

                &.nn2 {
                    right: - $gutter / 2;
                    padding: 0;

                    @media #{$media-sx} {
                        right: 0;
                    }
                }

                &.pp2.enabled,
                &.nn2.enabled {
                    pointer-events: all;
                    opacity: 1;
                    filter: none;
                }

                &.pp2.disabled,
                &.nn2.disabled {
                    pointer-events: none;
                    opacity: 0.2;
                    filter: grayscale(1);
                }
            }
        }
    }
    // .model__slider_features

    .model__details {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: $gutter * 3 0;

        @media #{$media-m} {
            padding: $gutter * 2 0;
        }

        @media #{$media-sm} {
            padding: $gutter 0;
        }


        &::before {
            position: absolute;
            content: '';
            left: 0;
            width: 100vw;
            height: 100%;
            margin-top: - $gutter * 3;
            background: url('../images/victoria_scritta.svg') no-repeat top left / 100%;
            z-index: -1;

            @media #{$media-s} {
                background-size: 200%;
            }

            @media #{$media-sx} {
                margin-top: - $gutter;
                background-size: 280%;
            }
        }

        .detail_accordion_row {
            width: 80%;
            background-color: var(--c-corporate-3);
            padding: $gutter / 1.2 $gutter / 1.5;
            margin-bottom: $gutter / 2;
            cursor: pointer;
            transition: $trans-default;

            @media #{$media-m} {
                width: 90%;
            }

            @media #{$media-sm} {
                padding: $gutter / 2 $gutter / 1.5;
            }

            @media #{$media-sx} {
                margin-bottom: $gutter / 4;
            }

            &:hover {
                background-color: var(--c-theme-2);
            }
            
            h4 {
                font-size: 2.2rem;
                font-weight: 700;
                text-transform: uppercase;
                position: relative;
                display: flex;
                align-items: center;

                @media #{$media-m} {
                    font-size: 2rem;
                }

                @media #{$media-sx} {
                    font-size: 1.8rem;
                }

                &::after {
                    content: '+';
                    position: absolute;
                    right: $gutter / 2;
                    width: 20px;
                    font-family: $f-family-menu;
                    font-weight: 400;
                    font-size: 6rem;

                    @media #{$media-m} {
                        font-size: 5rem;
                    }

                    @media #{$media-sm} {
                        font-size: 4rem;
                        right: 0;
                    }
                }
            }

            .content {
                max-height: 0px;
                overflow: hidden;
                transition: $trans-default;

                p {
                    @media #{$media-sx} {
                        font-size: 1.5rem;
                    }
                }

                .download_item {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: $gutter / 4 0;
                    border-bottom: 1px solid var(--c-grey-dark);
                    max-width: 50%;
                    transition: $trans-default;

                    @media #{$media-m} {
                        max-width: 80%;
                    }

                    @media #{$media-sm} {
                        max-width: none;
                    }

                    @media #{$media-sx} {
                        flex-direction: column;
                        align-items: flex-start;
                    }
                            
                    &:hover {
                        color: var(--c-accent);
                    }

                    h6 {
                        font-size: 1.8rem;

                        @media #{$media-sx} {
                            font-size: 1.6rem;
                        }
                    }

                    .download_link {
                        a {
                            display: flex;
                            align-items: center;
                            font-family: $f-family-menu;
                            font-size: 1.2rem;

                            img {
                                margin-right: $gutter / 2;

                                @media #{$media-sm} {
                                    width: 20px;
                                }
                            }

                            strong {
                                text-transform: uppercase;
                                margin-right: $gutter / 6;
                            }
                        }
                    }
                }
            }

            &.active {
                &:hover {
                    background-color: var(--c-corporate-3);
                    cursor: default;
                }

                h4 {
                    &::after {
                        content: '-';
                    }
                }

                .content {
                    margin-top: $gutter / 2;
                    max-height: none;
                }
            }
        }
    }
    // .model__details

    .model__gallery {
        display: flex;
        flex-wrap: wrap;
        padding: 0 $gutter / 2;
        margin-bottom: $gutter * 2;

        @media #{$media-m} {
            margin-bottom: $gutter;
        }

        .col-1 {
            width: 100%;

            h1 {
                font-size: 2.6rem;
                font-weight: 700;
                margin-bottom: $gutter * 1.5;

                @media #{$media-m} {
                    font-size: 2.2rem;
                    margin-bottom: $gutter;
                }

                @media #{$media-sx} {
                    font-size: 1.8rem;
                    margin-bottom: $gutter / 2;
                }
            }
        }

        .col-2 {
            width: 48%;
            display: flex;
            margin-right: 2%;
            overflow: hidden;
            position: relative;

            @media #{$media-s} {
                width: 100%;
                margin-right: 0;
                margin-bottom: 4%;
            }

            &::before {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: var(--c-accent);
                opacity: 0;
                z-index: 2;
                top: 0;
                left: 0;
                transition: $trans-default;
                pointer-events: none;
            }

            &:hover {
                figure {
                    transform: scale(1.2);
                }

                &::before {
                    opacity: 0.2;
                }
            }

            a, picture, figure {
                width: 100%;
                height: 100%;
            }
            
            figure {
                transition: $trans-easinout;
            }
        }

        .col-3 {
            width: 50%;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 1fr);
            grid-column-gap: 20px;
            grid-row-gap: 20px;

            @media #{$media-s} {
                width: 100%;
            }

            figure {
                position: relative;
                overflow: hidden;

                &::before {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background-color: var(--c-accent);
                    opacity: 0;
                    z-index: 2;
                    top: 0;
                    left: 0;
                    transition: $trans-default;
                    pointer-events: none;
                }

                &:hover {
                    picture {
                        transform: scale(1.2);
                    }
    
                    &::before {
                        opacity: 0.2;
                    }
                }

                a {
                    width: 100%;
                    height: 100%;
                }

                picture {
                    width: 100%;
                    transition: $trans-default;
                }
            }
        }
    }
    // .model__gallery
}
// .single-model 