/*------------------------------------*\
    Page Template: Chisiamo
    chisiamo.php
    .page-template-chisiamo
\*------------------------------------*/

.page-template-chisiamo {

    .chisiamo__header {
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: calc(100vh - var(--h-header));
        padding: $gutter $gutter * 1.5;

        @media #{$media-u} {
            position: relative;
        }

        @media #{$media-m} {
            padding-top: $gutter / 2;
        }

        @media #{$media-sm} {
            padding: $gutter;
            min-height: 0;
        }

        @media #{$media-sx} {
            padding: $gutter / 2;
        }

        figure.bg {
            position: absolute;
            z-index: -1;
            width: 100%;
            left: 0;
            top: 0;
            min-height: 100vh;
            margin-top: - $gutter * 4;

            @media #{$media-u} {
                height: 120%;
            }

            picture {
                width: 100%;
                height: 100vh;

                @media #{$media-u} {
                    height: 100%;
                }
            }
        }

        .col-1 {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            color: var(--c-white);

            h1 {
                font-size: 2.6rem;

                @media #{$media-bx} {
                    font-size: 2.2rem;
                }

                @media #{$media-sx} {
                    font-size: 1.8rem;
                }
            }

            h2 {
                font-size: 4rem;
                font-weight: 700;
                line-height: 1.3;
                margin-top: $gutter / 2;
                margin-bottom: $gutter * 1.5;

                @media #{$media-bx} {
                    font-size: 3.2rem;
                    margin-top: $gutter / 4;
                    margin-bottom: $gutter;
                }

                @media #{$media-sx} {
                    font-size: 2.3rem;
                    line-height: 1.4;
                }
            }

            .content {
                display: flex;
                gap: 140px;
                width: 80%;

                @media #{$media-bx} {
                    gap: 80px;
                    width: 90%;
                }

                @media #{$media-sm} {
                    flex-direction: column;
                    gap: 0;
                    width: 100%;
                }

                p {
                    font-size: 2rem;
                    font-family: $f-family-menu;
                    margin-bottom: $gutter / 2;
    
                    @media #{$media-bx} {
                        font-size: 1.8rem;
                    }
    
                    @media #{$media-sx} {
                        font-size: 1.6rem;
                    }
                }
            }

            .bottom {
                display: flex;
                align-items: flex-end;
                justify-content: center;
                gap: 10px;
                font-size: 7.6rem;
                font-family: $f-family-menu;
                line-height: 1;
                margin-top: $gutter;

                @media #{$media-bx} {
                    font-size: 4rem;
                }

                @media #{$media-sx} {
                    margin-top: 0;
                    margin-bottom: $gutter / 4;
                }

                span {
                    margin-bottom: - $gutter / 4;
                }

                figure {
                    width: 75%;

                    @media #{$media-bx} {
                        width: 40%;
                    }
                }
            }

        }

        //.col-1

    }
    // .chisiamo__header

    .chisiamo__section1 {
        display: flex;
        flex-wrap: wrap;
        margin-top: $gutter * 3;
        padding: 0 $gutter * 3;

        @media #{$media-bx} {
            margin-top: $gutter * 2;
            padding: 0 $gutter * 2;
        }

        @media #{$media-sm} {
            padding: 0 $gutter;
        }

        @media #{$media-s} {
            padding: 0 $gutter / 2;
        }

        @media #{$media-sx} {
            padding: 0;
        }

        .col-1 {
            width: 26%;

            @media #{$media-b} {
                width: 30%;
            }

            @media #{$media-s} {
                width: 100%;
            }

            picture {
                width: 100%;
            }
        }
        //.col-1

        .col-2 {
            width: 74%;
            padding-left: $gutter * 2;

            @media #{$media-b} {
                width: 70%;
            }

            @media #{$media-m} {
                padding-left: $gutter;
            }

            @media #{$media-s} {
                width: 100%;
                padding: $gutter / 2;
            }

            h1 {
                font-size: 2rem;
                margin-top: $gutter / 2;
                margin-bottom: $gutter / 2;

                @media #{$media-b} {
                    font-size: 1.8rem;
                }

                @media #{$media-sx} {
                    font-size: 1.6rem;
                }
            }

            h2 {
                font-size: 4.6rem;
                font-weight: 700;
                margin-bottom: $gutter / 2;

                @media #{$media-b} {
                    font-size: 2.6rem;
                }

                @media #{$media-sx} {
                    font-size: 2.3rem;
                }
                
            }

            p {
                font-family: $f-family-menu;
                font-size: 2rem;
                max-width: 70%;

                @media #{$media-bx} {
                    max-width: 90%;
                }

                @media #{$media-b} {
                    font-size: 1.8rem;
                    max-width: none;
                }

                @media #{$media-sx} {
                    font-size: 1.6rem;
                }
            }
        }
        //.col-2
    }
    // .chisiamo__section1

    .chisiamo__section2 {
        display: flex;
        flex-wrap: wrap;
        margin-top: $gutter * 2;
        padding: 0 $gutter * 3;

        @media #{$media-bx} {
            margin-top: $gutter * 2;
            padding: 0 $gutter * 2;
        }

        @media #{$media-m} {
            margin-top: $gutter;
        }

        @media #{$media-sm} {
            padding: 0 $gutter;
        }

        @media #{$media-s} {
            padding: 0 $gutter / 2;
        }

        @media #{$media-sx} {
            padding: 0;
            margin-top: 0;
        }
        
        .col-1 {
            width: 50%;
            background-color: var(--c-corporate-5);
            color: var(--c-white);
            padding: $gutter $gutter * 1.5;

            @media #{$media-m} {
                padding: $gutter $gutter;
                width: 100%;
            }

            @media #{$media-sx} {
                padding: $gutter / 2;
            }

            h1 {
                font-size: 2rem;
                margin-top: $gutter / 2;
                margin-bottom: $gutter / 2;

                @media #{$media-b} {
                    font-size: 1.8rem;
                }

                @media #{$media-sx} {
                    font-size: 1.6rem;
                }
            }

            h2 {
                font-size: 4.6rem;
                font-weight: 700;
                margin-bottom: $gutter / 2;

                @media #{$media-b} {
                    font-size: 2.6rem;
                }

                @media #{$media-sx} {
                    font-size: 2.3rem;
                }
                
            }

            p {
                font-family: $f-family-menu;
                font-size: 2rem;

                @media #{$media-b} {
                    font-size: 1.8rem;
                }

                @media #{$media-sx} {
                    font-size: 1.6rem;
                }
            }
        }
        //.col-1

        .col-2 {
            width: 50%;
            background-color: var(--c-corporate-5);

            @media #{$media-m} {
                width: 100%;
            }

            figure {
                height: 100%;
            }

            picture {
                width: 100%;
            }
        }
        //.col-2
    }
    // .chisiamo__section2

    .chisiamo__section3 {
        display: flex;
        flex-wrap: wrap;
        margin-top: $gutter * 3;
        margin-bottom: $gutter * 3;
        padding: 0 $gutter * 3;

        @media #{$media-bx} {
            margin-top: $gutter * 2;
            padding: 0 $gutter * 2;
        }

        @media #{$media-m} {
            margin-top: $gutter;
        }

        @media #{$media-sm} {
            padding: 0 $gutter;
        }

        @media #{$media-s} {
            padding: 0 $gutter / 2;
        }

        @media #{$media-sx} {
            padding: 0;
            margin-top: 0;
            margin-bottom: $gutter;
        }

        .col-1 {
            width: 100%;
            display: flex;
            justify-content: center;
            padding-bottom: $gutter * 2;

            @media #{$media-b} {
                padding-bottom: $gutter;
            }

            @media #{$media-m} {
                padding-bottom: 0;
            }

            img {
                @media #{$media-bx} {
                    max-width: 40rem;
                }

                @media #{$media-m} {
                    max-width: 30rem;
                }
            }
        }
        
        .col-2 {
            width: 50%;
            padding: 0 $gutter * 1.5;

            @media #{$media-bx} {
                padding-left: 0;
            }

            @media #{$media-m} {
                width: 100%;
            }

            @media #{$media-sm} {
                padding: 0 0 $gutter / 2 0;
            }

            @media #{$media-sx} {
                padding: 0 $gutter / 2 $gutter / 2;
            }

            h1 {
                font-size: 2rem;
                margin-top: $gutter / 2;
                margin-bottom: $gutter / 2;

                @media #{$media-b} {
                    font-size: 1.8rem;
                }

                @media #{$media-sx} {
                    font-size: 1.6rem;
                }
            }

            h2 {
                font-size: 4.6rem;
                font-weight: 700;
                margin-bottom: $gutter / 2;

                @media #{$media-b} {
                    font-size: 2.6rem;
                }

                @media #{$media-sx} {
                    font-size: 2.3rem;
                }
                
            }

        }
        //.col-1

        .col-3 {
            width: 50%;

            @media #{$media-m} {
                width: 100%;
            }

            picture {
                width: 100%;
            }

            p {
                margin-top: $gutter;

                @media #{$media-sx} {
                    padding: 0 $gutter / 2;
                }
            }
        }
        //.col-3

        p {
            font-family: $f-family-menu;
            font-size: 2rem;

            @media #{$media-bx} {
                max-width: 90%;
            }

            @media #{$media-b} {
                font-size: 1.8rem;
            }

            @media #{$media-m} {
                max-width: none;
            }

            @media #{$media-sx} {
                font-size: 1.6rem;
            }
        }
    }
    // .chisiamo__section3
}

// .page-template-chisiamo