/*------------------------------------*\
    Page Template: Showroom
    showroom.php
    .page-template-showroom
\*------------------------------------*/

.page-template-showroom {

    .showroom__header {
        display: flex;
        width: 100%;
        padding: $gutter * 1.5 $gutter * 1.5 $gutter $gutter * 1.5;

        @media #{$media-m} {
            padding-top: $gutter / 2;
        }

        @media #{$media-sm} {
            padding: $gutter;
        }

        @media #{$media-sx} {
            padding: $gutter / 2;
        }

        .col-1 {
            width: 100%;
            display: flex;
            flex-direction: column;

            h1 {
                font-size: 2.6rem;

                @media #{$media-bx} {
                    font-size: 2.2rem;
                }

                @media #{$media-sx} {
                    font-size: 1.8rem;
                }
            }

            h2 {
                font-size: 4rem;
                font-weight: 700;
                line-height: 1.3;
                margin-top: $gutter / 2;
                margin-bottom: $gutter * 1.5;

                @media #{$media-bx} {
                    font-size: 3.2rem;
                    margin-top: $gutter / 4;
                    margin-bottom: $gutter;
                }

                @media #{$media-sx} {
                    font-size: 2.3rem;
                    line-height: 1.4;
                }
            }

            p {
                font-size: 2rem;
                font-family: $f-family-menu;
                margin-bottom: $gutter / 2;
                max-width: 60%;

                @media #{$media-bx} {
                    font-size: 1.8rem;
                }

                @media #{$media-b} {
                    max-width: 90%;
                }

                @media #{$media-m} {
                    max-width: none;
                }

                @media #{$media-sx} {
                    font-size: 1.6rem;
                }
            }
        }

        //.col-1

    }
    // .showroom__header

    .showroom__sedi {
        display: flex;
        padding: 0 $gutter * 1.5;
        margin-bottom: $gutter * 2;

        @media #{$media-m} {
            padding: 0 $gutter;
        }

        @media #{$media-sx} {
            padding: 0 $gutter / 2;
            margin-bottom: 0;
        }

        .sedi_repeater {
            display: flex;
            flex-wrap: wrap;
            width: 100%;

            .box_sede {
                width: 33%;
                display: flex;
                flex-direction: column;
                align-items: center;
                position: relative;
                overflow: hidden;

                @media #{$media-b} {
                    width: 50%;
                }

                @media #{$media-sm} {
                    width: 100%;
                }

                h3 {
                    font-size: 3rem;
                    font-weight: 700;

                    @media #{$media-bx} {
                        font-size: 2.6rem;
                    }

                    @media #{$media-b} {
                        font-size: 2.4rem;
                    }

                    @media #{$media-sx} {
                        font-size: 2.2rem;
                    }
                }

                .indirizzo {
                    display: flex;
                    align-items: center;
                    gap: 20px;
                    margin-top: $gutter / 4;

                    @media #{$media-sx} {
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 10px;
                    }

                    span {
                        font-size: 2.2rem;
                        font-weight: 700;

                        @media #{$media-bx} {
                            font-size: 2rem;
                        }

                        @media #{$media-b} {
                            font-size: 1.8rem;
                        }
                    }

                    a {
                        font-family: $f-family-menu;
                        display: flex;
                        align-items: center;
                        gap: 15px;

                        @media #{$media-b} {
                            font-size: 1.5rem;
                        }
                    }
                }

                .content_icone {
                    margin: $gutter / 2 0;

                    .box_icone {
                        display: flex;
                        align-items: center;
                        margin-bottom: $gutter / 4;
    
                        .icona {
                            width: 50px;
                            display: flex;
                            justify-content: center;
                        }

                        a {
                            @media #{$media-b} {
                                font-size: 1.6rem;
                            }
                        }
                    }
                }

                a.btn {
                    text-transform: uppercase;

                    @media #{$media-sx} {
                        transform: scale(0.8);
                    }
                }

                .gallery {
                    margin: $gutter * 2 0;
                    width: 100%;
                    overflow: hidden;
                    position: relative;

                    @media #{$media-b} {
                        margin: $gutter 0 $gutter * 2 0;
                    }

                    @media #{$media-sm} {
                        width: 80%;
                    }

                    @media #{$media-s} {
                        width: 100%;
                        margin: $gutter 0;
                    }

                    .slick-arrow {
                        position: absolute;
                        bottom: 50%;
                        margin-bottom: -15px;
                        z-index: 2;
                        color: transparent;
                        border-radius: 20px;
                        background-color: var(--c-corporate-5);
                        background-image: url('../images/icon_arrow.svg');
                        background-repeat: no-repeat;
                        background-size: 50%;
                        border: 0;
                        outline: none;
                        width: 50px;
                        height: 30px;
                        padding: 1px 0;
                        background-position: center;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        transition: $trans-default;
        
                        &.pp2 {
                            transform: rotate(180deg);
                            pointer-events: none;
                            opacity: 0.2;
                            filter: grayscale(1);
                            left: $gutter / 2;
                        }
        
                        &.nn2 {
                            right: $gutter / 2;
                        }
        
                        &.pp2.enabled,
                        &.nn2.enabled {
                            pointer-events: all;
                            opacity: 1;
                            filter: none;
                        }
        
                        &.pp2.disabled,
                        &.nn2.disabled {
                            pointer-events: none;
                            opacity: 0.2;
                            filter: grayscale(1);
                        }
                    }
                }
            }
        }
    }
    // .showroom__sedi
}

// .page-template-showroom