/*------------------------------------*\
    Page Index: Blog
    index.php
    .archive
\*------------------------------------*/

.archive,
.single-post {

    .post--archive,
    .post--single {
        display: flex;
        flex-wrap: wrap;
        position: relative;

        .col-1 {
            width: 100%;
            padding: $gutter * 1.5 $gutter * 1.5 $gutter $gutter * 1.5;

            @media #{$media-m} {
                padding-top: $gutter / 2;
            }

            @media #{$media-sm} {
                padding: $gutter;
            }

            @media #{$media-sx} {
                padding: $gutter / 2;
            }

            h1 {
                font-size: 2.6rem;

                @media #{$media-bx} {
                    font-size: 2.2rem;
                }

                @media #{$media-sx} {
                    font-size: 1.8rem;
                }
            }

            h2 {
                font-size: 4rem;
                font-weight: 700;
                line-height: 1.3;
                margin-top: $gutter / 2;
                max-width: 60%;

                @media #{$media-bx} {
                    font-size: 3.2rem;
                    margin-top: $gutter / 4;
                    max-width: none;
                }

                @media #{$media-sm} {
                    font-size: 2.6rem;
                }

                @media #{$media-sx} {
                    font-size: 2.3rem;
                    line-height: 1.4;
                }
            }
        }

        //.col-1

        .blog--list {
            display: flex;
            flex-direction: column;
            padding: 0 $gutter * 1.5 $gutter * 3 $gutter * 1.5;

            @media #{$media-sm} {
                padding: 0 $gutter $gutter * 3 $gutter;
            }

            @media #{$media-sx} {
                padding: 0 $gutter / 2 $gutter $gutter / 2;
            }

            .box_news {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
                gap: 40px;

                @media #{$media-bx} {
                    gap: 25px;
                }

                @media #{$media-b} {
                    gap: 40px;
                }

                @media #{$media-sm} {
                    gap: 0;
                }

                .single_news {
                    width: 30%;
                    padding-bottom: $gutter;
                    border-bottom: 1px solid var(--c-corporate-1);
                    margin-bottom: $gutter;

                    @media #{$media-b} {
                        width: 46%;
                    }

                    @media #{$media-sm} {
                        width: 100%;
                        max-width: none;
                    }

                    .top {
                        display: flex;
                        height: $gutter * 1.2;
                        position: relative;
                        font-size: 2rem;
                        font-family: $f-family-menu;

                        @media #{$media-sx} {
                            font-size: 1.8rem;
                            height: auto;
                            margin-bottom: $gutter / 4;
                        }
                    }

                    .title {
                        font-size: 2.2rem;
                        font-weight: 700;
                        min-height: $gutter * 4;
                        overflow: hidden;

                        @media #{$media-bx} {
                            font-size: 2rem;
                            line-height: 1.4;
                            min-height: $gutter * 3.5;
                        }

                        @media #{$media-b} {
                            line-height: inherit;
                        }

                        @media #{$media-m} {
                            font-size: 1.8rem;
                        }

                        @media #{$media-sm} {
                            min-height: 0;
                        }
                    }

                }
            }
        }

        //.blog--list

        .blog--detail {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            padding: 0 $gutter * 1.5 0 $gutter * 1.5;
            margin-bottom: $gutter * 2;

            @media #{$media-m} {
                width: 100%;
            }

            @media #{$media-sm} {
                padding: 0 $gutter;
            }

            @media #{$media-sx} {
                padding: 0 $gutter / 2;
                margin-bottom: $gutter;
            }

            .col-1 {
                width: 55%;
                padding: 0 $gutter * 1.5 0 0;
                display: flex;
                flex-direction: column;

                @media #{$media-m} {
                    width: 100%;
                    padding-right: 0;
                }

                .time {
                    font-weight: $f-weight-300;
                    padding: 0.1rem 0.6rem;
                    margin-bottom: $gutter / 2;
                    display: inline-flex;
                    background-color: var(--c-corporate-5);
                    color: var(--c-white);
                }

                .body {
                    flex-direction: column;
                    padding-bottom: $gutter * 2;
                    font-weight: $f-weight-300;
                    text-align: left;

                    @media #{$media-sx} {
                        padding-bottom: 0;
                    }
                }

                .link {
                    svg {
                        transform: rotate(180deg);
                    }
                }
            }

            .col-2 {
                width: 45%;
                position: relative;

                @media #{$media-m} {
                    width: 100%;
                    margin-bottom: $gutter;
                    order: -1;
                }

                .time {
                    color: var(--c-corporate-2);
                    display: inline-block;
                    margin-bottom: $gutter / 3;
                }

                .content-gallery {
                    width: 600px;
                    height: 600px;

                    @media #{$media-s} {
                        width: 100%;
                        height: 400px;
                    }

                    @media #{$media-sx} {
                        height: 320px;
                    }

                    .gallery--single {

                        .item {
                            overflow: hidden;

                            @media #{$media-s} {
                                height: 400px;
                            }

                            @media #{$media-sx} {
                                height: 320px;
                            }

                            figure {
                                width: 600px;
                                height: 600px;

                                display: flex;
                                align-items: stretch;

                                @media #{$media-s} {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                        }
                    }
                }

            }

        }

        //.blog--detail

    }

    //.post--archive

    .link {
        display: flex;
        margin-top: $gutter / 4;

        @media #{$media-sx} {
            margin-top: $gutter / 2;
        }

        a {
            display: inline-flex;
            align-items: center;
            text-decoration: underline;

            .text {
                font-size: 1.8rem;
                font-weight: 500;
                font-family: $f-family-menu;
                text-transform: uppercase;
                transition: $trans-default;

                @media #{$media-sx} {
                    font-size: 1.6rem;
                }
            }

            svg {
                transition: $trans-default;
                margin-left: $gutter / 4;
                width: 20px;

                g,
                path {
                    stroke: var(--c-corporate-1);
                    stroke-width: 1px;
                }
            }

            &:hover {
                color: var(--c-accent);

                .text {
                    transform: translateX(10px);
                }

                svg {
                    transform: scale(1.1) translateX(10px);

                    g,
                    path {
                        stroke: var(--c-accent);
                        stroke-width: 1px;
                    }
                }
            }
        }
    }
}

//.archive