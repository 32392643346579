/*------------------------------------*\
    Page Template: Single product
    single-product.php
    .single-product
\*------------------------------------*/

.single-product {
    .main {
        overflow-x: hidden;
        overflow-y: clip;
    }

    .v_bg {
        width: 35vw;
        height: calc(100vh - var(--h-header));
        min-width: 560px;
        position: absolute;
        top: 0;
        pointer-events: none;
        z-index: -1;
        overflow: hidden;
        display: flex;

        svg {
            path {
                fill: var(--c-white);
            }
        }
    }

    .icons_callme {
        position: fixed;
        display: flex;
        bottom: $gutter / 2;
        right: $gutter / 2;
        gap: 20px;
        z-index: 10;

        .item {
            border-radius: 50%;
            background-color: var(--c-corporate-5);
            width: 50px;
            height: 50px;
            transition: $trans-default;

            @media #{$media-bx} {
                width: 40px;
                height: 40px;
            }

            &:hover {
                background-color: var(--c-black);
                transform: scale(1.05);
            }

            a {
                line-height: 1;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                @media #{$media-bx} {
                    padding: $gutter / 6;
                }
            }
        }
    }

    .product__header {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        min-height: calc(100vh - var(--h-header));
        padding: $gutter * 1.5 $gutter * 1.5;

        @media #{$media-sm} {
            padding: $gutter;
        }

        @media #{$media-sx} {
            padding: $gutter / 2;
        }

        .col-1 {
            width: 50%;

            @media #{$media-b} {
                width: 100%;
            }

            .top {
                @media #{$media-b} {
                    display: flex;
                }

                @media #{$media-s} {
                    align-items: center;
                }

                @media #{$media-sx} {
                    flex-direction: column;
                }

                .content {
                    @media #{$media-b} {
                        padding-right: $gutter;
                    }

                    @media #{$media-s} {
                        padding-right: 0;
                    }

                    h1 {
                        font-size: 2.6rem;

                        @media #{$media-bx} {
                            font-size: 2.2rem;
                        }

                        @media #{$media-sx} {
                            font-size: 1.8rem;
                        }
                    }

                    h2 {
                        font-size: 4rem;
                        font-weight: 700;
                        line-height: 1.3;
                        margin-top: $gutter / 2;
                        margin-bottom: $gutter;
                        width: 30vw;
                        max-width: 600px;

                        @media #{$media-bx} {
                            font-size: 3.2rem;
                            margin-top: $gutter / 4;
                            margin-bottom: $gutter / 2;
                        }

                        @media #{$media-b} {
                            width: 100%;
                            max-width: none;
                        }

                        @media #{$media-sx} {
                            font-size: 2.3rem;
                            line-height: 1.4;
                        }
                    }

                    p {
                        font-size: 2rem;
                        line-height: 1.4;
                        max-width: 90%;

                        @media #{$media-bx} {
                            font-size: 2rem;
                        }

                        @media #{$media-b} {
                            max-width: none;
                        }

                        @media #{$media-sx} {
                            font-size: 1.8rem;
                            margin-bottom: 0;
                        }
                    }
                }

                .brandType {
                    &.mobile {
                        display: none;

                        @media #{$media-b} {
                            display: flex;
                        }

                        @media #{$media-s} {
                            width: 30%;
                        } 
                        
                        @media #{$media-sx} {
                            width: auto;
                            max-width: 50%;
                        }
                    }
                }
            }

            //.top

            .box_caratteristiche {
                display: flex;
                flex-wrap: wrap;
                margin-top: $gutter * 2;

                @media #{$media-bx} {
                    margin-top: $gutter;
                }

                @media #{$media-sx} {
                    margin-top: $gutter / 2;
                    justify-content: space-evenly;
                }

                .box_single {
                    width: 25%;
                    display: flex;
                    align-items: center;
                    gap: 15px;
                    font-family: $f-family-menu;
                    margin-bottom: $gutter;

                    @media #{$media-s} {
                        width: 33%;
                    }

                    @media #{$media-sx} {
                        width: 50%;
                    }

                    .content {
                        display: flex;
                        flex-direction: column;
                        font-size: 2rem;

                        @media #{$media-u} {
                            font-size: 1.7rem;
                        }

                        @media #{$media-bx} {
                            line-height: 1.4;
                            font-size: 1.6rem;
                        }

                        @media #{$media-b} {
                            font-size: 2rem;
                        }

                        @media #{$media-m} {
                            font-size: 1.6rem;
                        }

                        @media #{$media-sx} {
                            font-size: 1.4rem;
                        }

                        span {
                            font-size: 1.2rem;
                            color: var(--c-corporate-2);
                        }
                    }
                }
            }

            // .box_caratteristiche

            .bottom {
                display: flex;
                gap: 80px;
                margin-top: $gutter * 2;

                @media #{$media-bx} {
                    margin-top: $gutter / 2;
                }

                @media #{$media-b} {
                    margin-bottom: $gutter;
                }

                @media #{$media-sx} {
                    margin-top: 0;
                }

                .box_prezzo {
                    display: flex;
                    gap: 30px;

                    &.listino {
                        h5 {
                            text-decoration: line-through;
                            color: var(--c-corporate-2);
                            font-size: 2rem;
                            font-weight: 600;
                            margin-top: $gutter / 8;

                            @media #{$media-bx} {
                                margin-top: 0;
                            }
                        }
                    }

                    h4 {
                        font-size: 2.6rem;

                        @media #{$media-bx} {
                            font-size: 1.8rem;
                        }
                    }

                    h5 {
                        font-family: $f-family-menu;
                        font-size: 2.4rem;
                        font-weight: 900;
                        color: var(--c-corporate-5);
                        display: flex;
                        flex-direction: column;

                        @media #{$media-bx} {
                            font-size: 2rem;
                        }

                        span {
                            font-size: 1.6rem;
                            font-weight: 400;
                            color: var(--c-corporate-1);
                        }
                    }

                    .calcolaRata {
                        font-size: 1.4rem;
                        text-decoration: underline;
                        font-family: $f-family-menu;
                        margin-left: $gutter / 4;
                        transition: $trans-default;

                        &:hover {
                            color: var(--c-accent);
                        }
                    }
                }
            }

            //.bottom
        }

        //.col-1

        .col-2 {
            width: 50%;
            display: flex;
            gap: 30px;

            @media #{$media-b} {
                width: 100%;
            }

            .brandType {
                width: 170px;

                &.desktop {
                    @media #{$media-b} {
                        display: none;
                    }
                }
            }

            .content {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                width: 100%;

                @media #{$media-b} {
                    align-items: center;
                }

                figure {
                    width: 100%;
                    max-width: 645px;
                    max-height: 600px;

                    picture {
                        width: 100%;
                    }
                }

                .vediGallery {
                    display: flex;
                    align-items: center;
                    align-self: center;
                    gap: 10px;
                    text-decoration: underline;
                    font-size: 1.6rem;
                    margin: $gutter / 2 0 0 0;
                    transition: $trans-default;

                    @media #{$media-bx} {
                        font-size: 1.4rem;
                    }

                    @media #{$media-b} {
                        margin: $gutter / 2 0;
                    }

                    svg {
                        @media #{$media-b} {
                            width: 25px;
                        }

                        path {
                            transition: $trans-default;
                        }
                    }

                    &:hover {
                        color: var(--c-accent);
                        transform: scale(1.05);

                        svg {
                            path {
                                fill: var(--c-accent);
                            }
                        }
                    }
                }
            }
        }

        //.col-2

        .col-3 {
            width: 100%;
            display: flex;
            flex-direction: column;

            .buttons {
                display: flex;
                gap: 20px;
                flex-wrap: wrap;
                justify-content: center;
                align-self: center;

                @media #{$media-bx} {
                    gap: 10px;
                }

                @media #{$media-sx} {
                    margin-bottom: $gutter / 2;
                }

                .btn {
                    text-transform: uppercase;

                    @media #{$media-bx} {
                        font-size: 1.3rem;
                        padding: 1.5rem 2rem 1.2rem;
                    }

                    @media #{$media-sx} {
                        flex: 1 0 auto;
                        justify-content: center;
                    }
                }
            }

            h6 {
                margin-top: $gutter / 2;

                @media #{$media-sx} {
                    font-size: 1.4rem;
                }
            }

        }

        //.col-3
    }

    // .product__header

    .product__details {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: $gutter * 3 0;

        @media #{$media-m} {
            padding: $gutter * 2 0;
        }

        @media #{$media-sm} {
            padding: $gutter 0;
        }


        &::before {
            position: absolute;
            content: '';
            left: 0;
            width: 100vw;
            height: 100%;
            margin-top: - $gutter * 3;
            background: url('../images/victoria_scritta.svg') no-repeat top left / 100%;
            z-index: -1;

            @media #{$media-s} {
                background-size: 200%;
            }

            @media #{$media-sx} {
                margin-top: - $gutter;
                background-size: 280%;
            }
        }

        .detail_accordion_row {
            width: 80%;
            background-color: var(--c-corporate-3);
            padding: $gutter / 1.2 $gutter / 1.5;
            margin-bottom: $gutter / 2;
            cursor: pointer;
            transition: $trans-default;

            @media #{$media-b} {
                width: 90%;
            }

            @media #{$media-sm} {
                padding: $gutter / 2 $gutter / 1.5;
            }

            @media #{$media-sx} {
                margin-bottom: $gutter / 4;
            }

            &:hover {
                background-color: var(--c-theme-2);
            }

            h4 {
                font-size: 2.2rem;
                font-weight: 700;
                text-transform: uppercase;
                position: relative;
                display: flex;
                align-items: center;

                @media #{$media-m} {
                    font-size: 2rem;
                }

                @media #{$media-sx} {
                    font-size: 1.6rem;
                }

                &::after {
                    content: '+';
                    position: absolute;
                    right: $gutter / 2;
                    width: 20px;
                    font-family: $f-family-menu;
                    font-weight: 400;
                    font-size: 6rem;

                    @media #{$media-m} {
                        font-size: 5rem;
                    }

                    @media #{$media-sm} {
                        font-size: 4rem;
                        right: 0;
                    }
                }
            }

            .content {
                max-height: 0px;
                overflow: hidden;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                transition: $trans-default;

                p {
                    @media #{$media-sx} {
                        font-size: 1.5rem;
                    }
                }

                .item {
                    width: 32%;
                    border-bottom: 1px solid #a6bdd5;
                    padding: 0.5rem 0;
                    display: flex;
                    align-items: center;

                    &.fullw {
                        width: 100%;
                    }

                    @media #{$media-m} {
                        width: 50%;
                    }

                    @media #{$media-s} {
                        width: 100%;
                    }

                    h5 {
                        font-weight: 700;
                        font-size: 2.2rem;
                        margin-bottom: $gutter / 4;

                        @media #{$media-m} {
                            font-size: 2rem;
                        }

                        @media #{$media-sx} {
                            font-size: 1.6rem;
                        }
                    }

                    .subitem {
                        display: flex;
                        width: 50%;

                        @media #{$media-b} {
                            width: 70%;
                        }

                        @media #{$media-m} {
                            width: 100%;
                        }

                        span {
                            width: 40%;
                            font-weight: 700;

                            @media #{$media-s} {
                                width: 50%;
                            }

                            @media #{$media-sx} {
                                font-size: 1.4rem;
                            }

                            &.title {
                                font-weight: 400;
                            }
                        }
                    }
                }
            }

            &.active {
                &:hover {
                    background-color: var(--c-corporate-3);
                    cursor: default;
                }

                h4 {
                    &::after {
                        content: '-';
                    }
                }

                .content {
                    margin-top: $gutter / 2;
                    max-height: none;
                }
            }
        }
    }

    // .product__details

    .product__gallery {
        display: flex;
        flex-wrap: wrap;
        padding: 0 $gutter / 2;
        margin-bottom: $gutter * 2;

        @media #{$media-m} {
            margin-bottom: $gutter;
        }

        .col-1 {
            width: 100%;

            h1 {
                font-size: 2.6rem;
                font-weight: 700;
                margin-bottom: $gutter * 1.5;

                @media #{$media-m} {
                    font-size: 2.2rem;
                    margin-bottom: $gutter;
                }

                @media #{$media-sx} {
                    font-size: 1.8rem;
                    margin-bottom: $gutter / 2;
                }
            }
        }

        .col-2 {
            width: 48%;
            display: flex;
            margin-right: 2%;
            overflow: hidden;
            position: relative;

            @media #{$media-s} {
                width: 100%;
                margin-right: 0;
                margin-bottom: 4%;
            }

            &::before {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: var(--c-accent);
                opacity: 0;
                z-index: 2;
                top: 0;
                left: 0;
                transition: $trans-default;
                pointer-events: none;
            }

            &:hover {
                figure {
                    transform: scale(1.2);
                }

                &::before {
                    opacity: 0.2;
                }
            }

            a,
            picture,
            figure {
                width: 100%;
                height: 100%;
            }

            figure {
                transition: $trans-easinout;
            }
        }

        .col-3 {
            width: 50%;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 1fr);
            grid-column-gap: 20px;
            grid-row-gap: 20px;

            @media #{$media-s} {
                width: 100%;
            }

            figure {
                position: relative;
                overflow: hidden;

                &::before {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background-color: var(--c-accent);
                    opacity: 0;
                    z-index: 2;
                    top: 0;
                    left: 0;
                    transition: $trans-default;
                    pointer-events: none;
                }

                &:hover {
                    picture {
                        transform: scale(1.2);
                    }

                    &::before {
                        opacity: 0.2;
                    }
                }

                a {
                    width: 100%;
                    height: 100%;
                }

                picture {
                    width: 100%;
                    transition: $trans-default;
                }
            }
        }
    }

    // .product__gallery
}

// .single-product 