/*------------------------------------*\
    Header
   #header.header
\*------------------------------------*/

.header {

    position: fixed;
    width: 100%;
    height: var(--h-header);
    padding: $gutter $gutter 0 $gutter;
    z-index: 2;
    transition: $trans-default;

    @media #{$media-m} {
        height: var(--h-header) - 2rem;
    }

    @media #{$media-sx} {
        padding: $gutter / 1.2 $gutter / 2 $gutter / 2 $gutter / 2;
    }

    &.sticky {
        background-color: var(--c-bg);
        -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,0.2);
        box-shadow: 0 2px 5px 0 rgba(0,0,0,0.2);
        height: var(--h-header) - 4rem;
        padding-top: $gutter / 2;

        @media #{$media-m} {
            padding-bottom: $gutter / 2;
        }

        .header__brand {
            h1 {
                height: 50px;

                @media #{$media-sx} {
                    height: auto;
                }

                svg {
                    margin-top: -0.5rem;
                }
            }
        }
    }
}

// .header







/*------------------------------------*\
    Header content
   .header__content
\*------------------------------------*/

.header__content {

    position: relative;
    display: flex;
    justify-content: space-between;
    height: 100%;

    @media #{$media-m} {
        flex-direction: row-reverse;
        align-items: center;
    }

    @media #{$media-sx} {
        align-items: flex-end;
    }

    .header__hamburger {
        flex: 1;

        @media #{$media-m} {
            display: flex;
            justify-content: flex-end;
            order: -1;
            flex: 0;
            margin-left: $gutter / 2;
        }

        @media #{$media-sx} {
            margin-left: $gutter / 4;
        }

        .icon {
            border: none;
            padding: 0;
            background-color: transparent;
            position: relative;

            width: 5rem;
            height: 5rem;

            @media #{$media-sx} {
                width: 4rem;
                height: 4rem;
            }

            &:hover,
            &:focus-within {
                #menuBackground {
                    fill: var(--c-corporate-1);
                }
            }

            svg {
                display: block;
                width: 100%;
                height: 100%;

                path {
                    transition: $trans-easinout;
                }
            }

        }
    }

    // .header__hamburger

    .header__brand {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        h1 {
            width: 358px;
            height: 50px;
            transition: $trans-default;

            @media #{$media-s} {
                width: 50vw;
                height: auto;
            } 
            
            @media #{$media-sx} {
                width: 45vw;
                margin-left: $gutter / 4;
                margin-bottom: $gutter / 4;
            }

            a {
                display: flex;
                align-items: center;
            }

            svg {
                width: 100%;
                height: 100%;
            }
        }
    }

    // .header__brand

    .header__icons {
        flex: 1;
        display: flex;
        justify-content: flex-end;

        @media #{$media-m} {
            order: -1;
        }

        .link {
            margin: $gutter / 2;

            @media #{$media-s} {
                margin: $gutter / 4;
            }

            @media #{$media-sx} {
                margin: $gutter / 8;
            }

            &.link--login {
                width: 2.5rem;
                height: 2.5rem;
            }

            &.link--wishlist {
                width: 2.7rem;
                height: 2.4rem;
            }

            &.active {
                svg {
                    transform: scale(1.1);
    
                    path {
                        fill: var(--c-accent);
                    }
                }
            }

            svg {
                transition: $trans-default;

                path {
                    transition: $trans-default;
                }
            }
        }

        .link:hover {
            svg {
                transform: scale(1.1);

                path {
                    fill: var(--c-accent);
                }
            }
        }

        .brand_nissan {
            width: 6.5rem;
            height: 5.4rem;
            margin: 0 $gutter / 2;

            @media #{$media-m} {
                display: none;
            }
        }
    }

    // .header__icons

}

// .header__content


/*------------------------------------*\
    Header Menu -> header_menu
    Header Navigation
    nav.header_menu
\*------------------------------------*/

.header_menu {
    font-family: $f-family-menu;
    font-size: 1.8rem;
    font-weight: 500;
    text-transform: uppercase;

    @media #{$media-m} {
        display: none;
    }

    ul {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        li {
            transition: $trans-default;
            position: relative;

            &::before {
                content: '';
                position: absolute;
                width: 0px;
                height: 3px;
                bottom: -2px;
                left: 0;
                background-color: var(--c-bg);
                transition: $trans-easinout;
            }

            a {
                height: 100%;
                display: inline-block;
            }
        }

        li:hover {
            color: var(--c-accent);

            &::before {
                width: 100%;
                background-color: var(--c-accent);
            }
        }
    }

}

// .header_menu










/*------------------------------------*\
    Main Navigation
   nav.main__nav
\*------------------------------------*/

.main__nav {
    color: var(--c-corporate-3);
    background: var(--c-corporate-5) url('../images/V.svg') no-repeat bottom left -7rem / contain;

    position: fixed;
    width: 73rem;
    height: auto;
    min-height: 100vh;
    max-height: 100vh;
    left: -73rem;
    top: 0;
    z-index: 99999;
    overflow-x: hidden;
    overflow-y: auto;
    padding: $gutter;

    will-change: transform, opacity, visibility;
    transform: translateX(-100%);
    opacity: 0;
    visibility: hidden;
    transition: transform 0.5s ease, opacity 0.5s ease, visibility 0.5s;

    @media #{$media-m} {
        width: 100vw;
    }

    @media #{$media-sx} {
        display: flex;
        flex-direction: column;
    }

    :focus { outline: .1rem dashed var(--c-accent); outline-offset: .2rem; }

    .logo_close {
        @media #{$media-m} {
            display: flex;
            justify-content: space-between;
        }
    }

    h1 {
        width: 25%;
        display: none;

        @media #{$media-m} {
            display: block;
        }

        @media #{$media-sx} {
            width: 40%;
        }

        svg {
            path {
                fill: var(--c-white);
            }
        }
    }

    .nav_close {
        width: 2.3rem;
        height: 2.3rem;
        border: none;
        padding: 0;
        background: transparent;

        svg {
            display: block;
            width: 100%;
            height: 100%;

            path {
                transition: $trans-default;
            }
        }

        &:hover {
            svg {
                path {
                    stroke: var(--c-corporate-1);
                }
            }
        }
    }
    // .nav_close

    .main_menu {
        font-family: $f-family-menu;
        font-size: 3.2rem;
        font-weight: 500;
        margin-top: $gutter / 2;

        @media #{$media-sx} {
            font-size: 2.4rem;
        }

        li {
            padding-bottom: $gutter / 4;
            line-height: 1.5;
        }

        .sub-menu {
            font-size: 2.6rem;
            font-weight: 400;
            margin: $gutter / 4 $gutter $gutter / 2 $gutter;

            @media #{$media-sx} {
                font-size: 2.2rem;
                margin: $gutter / 4 $gutter / 2;
            }

            li {
                padding-bottom: 0.2rem;
            }
        }

        a {
            transition: $trans-default;
            &:hover {
                color: var(--c-black);
            }
        }

        .arrowLine {
            display: none;
        }
    }
    // .main_menu

    .btn {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: $gutter / 4 $gutter * 1.3 $gutter / 3 $gutter * 1.3;
        font-family: $f-family-menu;

        @media #{$media-sx} {
            padding: $gutter / 4 $gutter $gutter / 3 $gutter;
        }

        &.btn--prenotatestdrive {
            background-color: var(--c-corporate-3);
            color: var(--c-corporate-1);
            flex-direction: column;
            align-items: flex-start;
            font-weight: 600;
            border-radius: 30px;
            transition: $trans-easinout;

            @media #{$media-sx} {
                transform: scale(0.8);
            }

            .small {
                font-size: 1.4rem;
            }

            .big {
                font-size: 2rem;
                text-transform: uppercase;
                line-height: 1;
            }

            &:hover {
                background-color: var(--c-corporate-1);
                color: var(--c-white);
            }
        }
    }

    .main__nav_icons {
        margin-top: $gutter;
        display: flex;
        justify-content: center;
        gap: 15px;

        svg {
            g, path, rect {
                transition: $trans-default;
            }
        }

        .login_wishlist, .socials {
            display: flex;
            gap: 10px;

            svg {
                width: 45px;
                height: 45px;

                @media #{$media-sx} {
                    width: 35px;
                    height: 35px;
                }

                path {
                    fill: var(--c-white);
                }
            }

            @media #{$media-sx} {
                display: flex;
                gap: 15px;
            }
        }

        a {
            &.link--wishlist {
                margin-right: $gutter;

                @media #{$media-sx} {
                    margin-right: 0;
                }
            }

            &:hover, &.active {
                svg {
                    path {
                        fill: var(--c-black);
                    }

                    g {
                        stroke: var(--c-black);
                    }
                }
            }
        }
    }

    // .main__nav_icons
}

// .main__nav

.main__nav-opened {
    .main__nav {
        transform: translateX(0);
        opacity: 1;
        visibility: visible;
        left: 0;
    }
}

// .main__nav-opened

/*------------------------------------*\
    Shared
\*------------------------------------*/
.menu-item {
    &.current_page_item {
        color: var(--c-accent);
    }
    &.current-menu-item {
        color: var(--c-accent);
    }
}
.main_menu {
    .menu-item {
        &.current_page_item {
            color: var(--c-black);
        }
        &.current-menu-item {
            color: var(--c-black);
        }
    }  
}