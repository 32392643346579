/*------------------------------------*\
    Page Template: Lavora con noi
    lavora-con-noi.php
    .page-template-lavora-con-noi
\*------------------------------------*/

.page-template-lavora-con-noi {

    .lavora_con_noi__header {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        padding: $gutter * 1.5 $gutter * 1.5 $gutter $gutter * 1.5;

        @media #{$media-m} {
            padding-top: $gutter / 2;
        }

        @media #{$media-sm} {
            padding: $gutter;
        }

        @media #{$media-sx} {
            padding: $gutter / 2;
        }

        .col-1 {
            width: 60%;
            display: flex;
            flex-direction: column;

            @media #{$media-sm} {
                width: 100%;
            }

            h1 {
                font-size: 2.6rem;

                @media #{$media-bx} {
                    font-size: 2.2rem;
                }

                @media #{$media-sx} {
                    font-size: 1.8rem;
                }
            }

            h2 {
                font-size: 4rem;
                font-weight: 700;
                line-height: 1.3;
                margin-top: $gutter / 2;
                margin-bottom: $gutter * 1.5;

                @media #{$media-bx} {
                    font-size: 3.2rem;
                    margin-top: $gutter / 4;
                    margin-bottom: $gutter;
                }

                @media #{$media-sx} {
                    font-size: 2.3rem;
                    line-height: 1.4;
                    margin-bottom: $gutter / 4;
                }
            }

            p {
                font-size: 2rem;
                font-family: $f-family-menu;
                margin-bottom: $gutter / 2;

                @media #{$media-bx} {
                    font-size: 1.8rem;
                }

                @media #{$media-sx} {
                    font-size: 1.6rem;
                }
            }
        }

        //.col-1

        .col-2 {
            width: 40%;
            display: flex;
            justify-content: flex-end;

            @media #{$media-sm} {
                width: 100%;
            }

            figure {
                align-self: center;

                @media #{$media-sm} {
                    display: none;
                }
            }
        }
        //.col-2

        .col-3 {
            width: 50%;

            @media #{$media-m} {
                margin-top: $gutter;
                order: 2;
                width: 100%;
            }
        }
        //.col-3

        .col-4 {
            width: 50%;
            display: flex;
            flex-direction: column;
            padding-left: $gutter * 4;

            @media #{$media-b} {
                padding-left: $gutter * 1.5;
            }

            @media #{$media-m} {
                margin-top: $gutter;
                padding-left: 0;
                width: 100%;
            }

            h3 {
                font-size: 3rem;
                font-weight: 700;

                @media #{$media-s} {
                    font-size: 2.6rem;
                }

                @media #{$media-sx} {
                    font-size: 2.2rem;
                }
            }

            details {
                margin-top: $gutter / 1.5;

                @media #{$media-sx} {
                    margin-top: $gutter / 2;
                }

                h4 {
                    font-size: 1.8rem;
                    font-family: $f-family-menu;
                    font-weight: 700;
                    color: var(--c-corporate-5);
                }

                summary {
                    padding: $gutter / 2 0 $gutter / 2 $gutter * 1.5;
                    border-bottom: 1px dashed var(--c-black);
                    background: url('../images/V_small_black.svg') no-repeat left 1rem center / 23px 26px;
                    cursor: pointer;
                    position: relative;
                    display: flex;
                    align-items: center;

                    &::after {
                        content: '';
                        position: absolute;
                        right: 0;
                        width: 20px;
                        height: 20px;
                        background: url('../images/icon_arrow_down.svg') no-repeat right center;
                    }
                }

                .desc {
                    display: flex;
                    flex-direction: column;
                    margin-top: $gutter / 2;
                    padding-left: $gutter * 1.5;

                    @media #{$media-sx} {
                        padding-left: 0;
                    }

                    a.link {
                        display: flex;
                        align-items: center;
                        gap: 11px;
                        font-size: 1.4rem;
                        text-decoration: underline;

                        img {
                            width: 16px;
                        }
                    }
                }
            }
        }
        //.col-4

    }
    // .lavora_con_noi__header
}

// .page-template-lavora-con-noi