/*------------------------------------*\
    Page Template: Contatti
    contatti.php
    .page-template-contatti
\*------------------------------------*/

.page-template-contatti {

    .contatti__header {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        padding: $gutter * 1.5 $gutter * 1.5 $gutter $gutter * 1.5;

        @media #{$media-m} {
            padding-top: $gutter / 2;
        }

        @media #{$media-sm} {
            padding: $gutter;
        }

        @media #{$media-sx} {
            padding: $gutter / 2;
        }

        .col-1 {
            width: 100%;
            display: flex;
            flex-direction: column;

            h1 {
                font-size: 2.6rem;

                @media #{$media-bx} {
                    font-size: 2.2rem;
                }

                @media #{$media-sx} {
                    font-size: 1.8rem;
                }
            }

            h2 {
                font-size: 4rem;
                font-weight: 700;
                line-height: 1.3;
                margin-top: $gutter / 2;
                margin-bottom: $gutter * 1.5;

                @media #{$media-bx} {
                    font-size: 3.2rem;
                    margin-top: $gutter / 4;
                    margin-bottom: $gutter;
                }

                @media #{$media-sx} {
                    font-size: 2.3rem;
                    line-height: 1.4;
                    margin-bottom: $gutter / 4;
                }
            }

            p {
                font-size: 2rem;
                font-family: $f-family-menu;
                margin-bottom: $gutter / 2;
                max-width: 50%;

                @media #{$media-bx} {
                    font-size: 1.8rem;
                }

                @media #{$media-b} {
                    max-width: 60%;
                }

                @media #{$media-m} {
                    max-width: 70%;
                }

                @media #{$media-sm} {
                    max-width: none;
                }

                @media #{$media-sx} {
                    font-size: 1.6rem;
                }
            }
        }

        //.col-1

        .col-2 {
            width: 50%;

            @media #{$media-b} {
                width: 70%;
            }

            @media #{$media-sm} {
                width: 100%;
            }
        }
        //.col-2

        .col-3 {
            width: 50%;
            display: flex;
            justify-content: center;

            @media #{$media-b} {
                width: 30%;
            }

            @media #{$media-m} {
                padding-left: $gutter;
            }

            figure {
                margin-top: - $gutter * 18;
                align-self: center;

                @media #{$media-bx} {
                    margin-top: - $gutter * 16;
                }

                @media #{$media-b} {
                    margin-top: - $gutter * 12;
                }

                @media #{$media-m} {
                    margin-top: - $gutter * 8;
                }

                @media #{$media-sm} {
                    display: none;
                }
            }
        }
        //.col-3

    }
    // .contatti__header
}

// .page-template-contatti