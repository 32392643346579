/*------------------------------------*\
    Page Template: Concessionaria Nissan
    concessionaria-nissan.php
    .page-template-concessionaria-nissan
\*------------------------------------*/

.page-template-concessionaria-nissan {

    .concessionaria_nissan__header {
        display: flex;
        width: 100%;
        padding: $gutter * 1.5 0;

        @media #{$media-m} {
            padding-top: $gutter / 2;
        }

        @media #{$media-sx} {
            padding-bottom: 0;
        }

        .col-1 {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            h1 {
                font-size: 2.6rem;

                @media #{$media-bx} {
                    font-size: 2.2rem;
                }

                @media #{$media-sx} {
                    font-size: 1.8rem;
                    padding: 0 $gutter / 2;
                    text-align: center;
                }
            }

            h2 {
                font-size: 4.6rem;
                font-weight: 700;
                line-height: 1.3;
                margin-top: $gutter / 2;
                margin-bottom: $gutter * 1.5;

                @media #{$media-bx} {
                    font-size: 3.2rem;
                    margin-top: $gutter / 4;
                    margin-bottom: $gutter;
                }

                @media #{$media-sx} {
                    font-size: 2.3rem;
                    line-height: 1.4;
                    margin-bottom: 0;
                }
            }

            .logoNissan {
                @media #{$media-sx} {
                    width: 80%;
                    margin-top: $gutter / 2;
                }
            }

            .content {
                display: flex;
                gap: 140px;
                margin-top: $gutter;
                padding: 0 $gutter * 1.5;

                @media #{$media-bx} {
                    gap: 100px;
                }

                @media #{$media-sm} {
                    flex-direction: column;
                    gap: 0;
                    padding: 0 $gutter;
                }

                @media #{$media-sx} {
                    padding: 0 $gutter / 2;
                    margin-top: $gutter / 2;
                }

                p {
                    font-size: 2rem;
                    font-family: $f-family-menu;
    
                    @media #{$media-bx} {
                        font-size: 1.8rem;
                    }
    
                    @media #{$media-sx} {
                        font-size: 1.6rem;
                    }
                }
            }

            .gallery {
                width: 100%;
                display: flex;
                justify-content: center;
                margin-top: $gutter;

                @media #{$media-m} {
                    flex-wrap: wrap;
                }

                @media #{$media-sx} {
                    margin-top: $gutter / 2;
                }

                figure {
                    @media #{$media-m} {
                        width: 50%;
                    }

                    @media #{$media-sx} {
                        width: 100%;
                    }
                }

                picture {
                    width: 100%;
                }
            }
        }

        //.col-1

    }
    // .concessionaria_nissan__header
}

// .page-template-concessionaria-nissan