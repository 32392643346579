/*------------------------------------*\
    Page Template: Homepage
    homepage.php
    .page-template-homepage
\*------------------------------------*/

.page-template-homepage {

    .main {
        overflow-x: hidden;
        overflow-y: clip;
    }

    .v_bg {
        width: 35vw;
        height: calc(100vh - var(--h-header));
        min-width: 560px;
        position: absolute;
        top: 0;
        pointer-events: none;
        z-index: -1;
        overflow: hidden;
        display: flex;

        svg {
            path {
                fill: var(--c-white);
            }
        }
    }

    .home__header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        width: 100%;
        min-height: calc(100vh - var(--h-header));

        .product_search {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-top: $gutter;

            @media #{$media-bx} {
                margin-top: $gutter / 2;
            }

            .searchProductForm {
                position: relative;

                .gfield {
                    position: relative;
                }

                .gfield_label {
                    font-size: 1.8rem;
                    font-weight: 500;
                    position: absolute;
                    z-index: 10;
                    left: 3rem;
                    top: 0.8rem;
                    pointer-events: none;
                    transition: $trans-default;
                }

                .gfield--type-email {
                    display: none;
                    pointer-events: none;
                }

                input {
                    background-color: var(--c-white);
                    border: 1px solid var(--c-black);
                    border-radius: 40px;
                    padding: $gutter / 3 3rem;
                    min-width: 358px;

                    @media #{$media-sx} {
                        min-width: 85vw;
                    }

                    &::placeholder {
                        font-size: 1.4rem;
                        font-style: italic;
                        color: var(--c-corporate-2);
                        transform: translateY(10px);
                        transition: $trans-default;
                    }
                }

                input.focused {
                    &::placeholder {
                        transform: translateY(0);
                        opacity: 0;
                    }
                }

                .gfield_validation_message {
                    display: none;
                }

                .gform_footer {
                    margin: 0;
                    padding: 0;
                    display: inline-flex;
                    position: absolute;
                    bottom: 14px;
                    right: 18px;

                    button {
                        width: 30px;
                        height: 30px;
                        background: url(../images/icon_lens.svg) no-repeat center / contain;
                        border-radius: 0;
                        padding: 0;
                        margin: 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: transparent;
                    }
                }
            }

        }

        // .product_search

        .container_slider {
            margin-top: 7vh;
            padding: 0 $gutter * 2;

            @media #{$media-bx} {
                margin-top: 5vh;
            }

            @media #{$media-m} {
                padding: 0 $gutter;
            }

            @media #{$media-sm} {
                padding-bottom: $gutter / 2;
            }

            @media #{$media-sx} {
                padding: 0 $gutter / 2 $gutter / 2 $gutter / 2;
            }

            .sliderHomepage {
                .item {
                    padding: 0 $gutter / 2;

                    @media #{$media-sx} {
                        padding: 0;
                    }

                    .content {
                        display: flex;
                        gap: 40px;

                        @media #{$media-sm} {
                            flex-wrap: wrap;
                            gap: 0;
                        }

                        .prezzi {
                            display: flex;
                            gap: 40px;
                            margin-top: $gutter;
                            font-family: $f-family-menu;

                            @media #{$media-bx} {
                                margin-top: $gutter / 2;
                            }

                            @media #{$media-sm} {
                                display: none;
                            }

                            .box_prezzo {

                                h4 {
                                    font-size: 1.6rem;
                                    font-weight: 900;
                                }

                                h5 {
                                    font-size: 2.4rem;
                                    font-weight: 900;
                                    color: var(--c-corporate-5);
                                }
                            }
                        }

                        .pulsanti {
                            display: flex;
                            gap: 20px;
                            margin-top: $gutter / 2;

                            @media #{$media-bx} {
                                margin-top: 0;
                            }

                            @media #{$media-sm} {
                                display: none;
                            }

                            @media #{$media-sx} {
                                margin-bottom: $gutter / 2;
                            }

                            .btn {
                                font-family: $f-family-menu;
                                line-height: 1.2;
                                text-transform: uppercase;
                                font-size: 2rem;
                                font-weight: 500;
                                margin-top: $gutter;

                                @media #{$media-m} {
                                    font-size: 1.8rem;
                                }

                                @media #{$media-sx} {
                                    margin-top: $gutter / 2;
                                    padding: 1rem 1rem 1rem 1rem;
                                    font-size: 1.6rem;
                                }

                                &.btn--scopri {
                                    background-color: var(--c-corporate-5);
                                    color: var(--c-white);
                                    padding: 1.5rem 8rem 1.2rem 8rem;
                                    justify-content: center;
                                    text-decoration: none;

                                    @media #{$media-b} {
                                        padding: 1.5rem 4rem 1.2rem 4rem;
                                    }

                                    @media #{$media-s} {
                                        padding: 1.5rem 2rem 1.2rem 2rem;
                                        flex: 2;
                                    }
                                }

                                &.btn--prenota {
                                    background-color: var(--c-corporate-3);
                                    color: var(--c-corporate-1);
                                    text-decoration: none;

                                    @media #{$media-s} {
                                        padding: 1.5rem 2rem 1.2rem 2rem;
                                    }
                                }

                                &.btn--scopri:hover {
                                    background-color: var(--c-corporate-1);
                                    color: var(--c-white);
                                }

                                &.btn--prenota:hover {
                                    background-color: var(--c-corporate-1);
                                    color: var(--c-white);
                                }
                            }
                        }

                        .col-1 {
                            width: 50%;
                            padding-bottom: $gutter;

                            @media #{$media-m} {
                                width: 100%;
                            }

                            @media #{$media-sx} {
                                text-align: center;
                            }

                            h1 {
                                font-size: 2.6rem;

                                @media #{$media-bx} {
                                    font-size: 2.4rem;
                                }

                                @media #{$media-sx} {
                                    font-size: 1.8rem;
                                }
                            }

                            p {
                                font-size: 4rem;
                                font-weight: 700;
                                line-height: 1.3;
                                margin-top: $gutter / 2;
                                margin-bottom: $gutter;

                                @media #{$media-bx} {
                                    font-size: 3.6rem;
                                    margin-top: $gutter / 4;
                                    margin-bottom: $gutter / 2;
                                }

                                @media #{$media-sx} {
                                    font-size: 2.4rem;
                                    line-height: 1.4;
                                }
                            }

                            h2 {
                                font-size: 2.4rem;
                                line-height: 1.4;

                                @media #{$media-bx} {
                                    font-size: 2.2rem;
                                }

                                @media #{$media-sx} {
                                    font-size: 1.8rem;
                                }
                            }

                        }

                        .col-2 {
                            width: 50%;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            position: relative;

                            @media #{$media-sm} {
                                width: 100%;
                            }

                            .dati_mobile {
                                display: none;

                                @media #{$media-sm} {
                                    display: block;

                                    .prezzi,
                                    .pulsanti {
                                        display: flex;
                                    }
                                }
                            }

                            .brandType {
                                position: absolute;
                                top: 0;
                                right: 0;

                                @media #{$media-m} {
                                    max-width: 10rem;
                                }

                                @media #{$media-sm} {
                                    top: - $gutter * 1.5;
                                }

                                @media #{$media-sx} {
                                    max-width: 8rem;
                                }
                            }
                        }
                    }
                }

                //.item
            }

            //.sliderHomepage
        }

    }

    //.home__header

    .home__usato {
        display: flex;
        gap: 40px;
        padding: $gutter * 2 0 $gutter * 3 $gutter * 2;
        background-color: var(--c-white);
        overflow: hidden;

        @media #{$media-m} {
            padding: $gutter * 2 0 $gutter * 3 $gutter;
        }

        @media #{$media-sx} {
            padding: $gutter 0 $gutter * 3 $gutter / 2;
        }

        .dati_mobile {
            display: none;

            @media #{$media-m} {
                display: block;
            }
        }

        .box_info_usato {
            width: 40%;
            display: flex;
            flex-wrap: wrap;
            position: relative;

            @media #{$media-m} {
                display: none;
            }

            article {
                width: 100%;
                visibility: hidden;
                opacity: 0;
                pointer-events: none;
                transition: $trans-easinout;
                transform: translateX(40rem);
                position: absolute;
                top: 0;
                left: 0;

                &.selected {
                    display: block;
                    transform: translateX(0);
                    visibility: visible;
                    opacity: 1;
                    pointer-events: all;
                }

                a {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                }

                h6 {
                    min-height: 0;
                }

                span {
                    display: inline-block;
                    word-wrap: break-word;
                }
            }
        }

        //.box_info_usato

        .box_slider_usato {
            width: 60%;

            @media #{$media-m} {
                width: 100%;
            }

            .sliderUsatoHomepage {
                .slick-slide {
                    margin: 0 $gutter / 4;
                }

                .slick-list {
                    margin: 0 -$gutter / 4;
                }

                .slick-current {
                    figure {
                        border: 10px solid var(--c-corporate-5);
                        box-sizing: border-box;
                    }
                }

                .slick-arrow {
                    position: absolute;
                    bottom: - $gutter * 1.5;
                    z-index: 2;
                    color: transparent;
                    border-radius: 20px;
                    background-color: var(--c-corporate-5);
                    background-image: url('../images/icon_arrow.svg');
                    background-repeat: no-repeat;
                    background-size: 50%;
                    border: 0;
                    outline: none;
                    width: 51px;
                    height: 30px;
                    background-position: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    transition: $trans-default;

                    &.slick-prev {
                        transform: rotate(180deg);
                        left: 14rem;
                        pointer-events: none;
                        opacity: 0.2;
                        filter: grayscale(1);

                        @media #{$media-m} {
                            left: calc(45% - 4rem);
                        }
                    }

                    &.slick-next {
                        left: 24rem;

                        @media #{$media-m} {
                            right: calc(45% - 4rem);
                            left: auto;
                        }
                    }

                    &.slick-prev.enabled,
                    &.slick-next.enabled {
                        pointer-events: all;
                        opacity: 1;
                        filter: none;
                    }

                    &.slick-prev.disabled,
                    &.slick-next.disabled {
                        pointer-events: none;
                        opacity: 0.2;
                        filter: grayscale(1);
                    }
                }

                .item {
                    width: 420px;
                    min-width: 280px !important;

                    figure {
                        width: 100%;
                        height: 283px;
                        overflow: hidden;

                        @media #{$media-m} {
                            height: 240px;
                        }

                        @media #{$media-sx} {
                            height: 210px;
                        }

                        img {
                            transition: $trans-default;

                            &:hover {
                                transform: scale(1.2);
                            }
                        }
                    }

                    .dati_auto_mobile {
                        a {
                            display: flex;
                            align-items: center;
                            gap: 10px;
                        }

                        span {
                            display: inline-block;
                            word-break: break-word;
                        }

                        h6 {
                            min-height: $gutter * 1.8;
                        }

                        .box_single {
                            @media #{$media-sx} {
                                width: 50%;
                            }
                        }
                    }
                }
            }
        }

        //.box_slider_usato
    }

    //.home__usato

    .home__company {
        display: flex;
        flex-wrap: wrap;
        margin-top: $gutter * 3;
        padding-top: $gutter * 2;
        font-family: $f-family-menu;
        font-size: 2rem;
        overflow: hidden;

        @media #{$media-m} {
            margin-top: $gutter;
        }

        @media #{$media-s} {
            font-size: 2rem;
        }

        @media #{$media-sx} {
            font-size: 1.8rem;
            padding-top: $gutter;
        }

        &::before {
            position: absolute;
            content: '';
            left: 0;
            width: 100vw;
            height: 100%;
            margin-top: - $gutter * 3;
            background: url('../images/victoria_scritta.svg') no-repeat top left / 100%;
            z-index: -1;

            @media #{$media-s} {
                background-size: 200%;
            }

            @media #{$media-sx} {
                margin-top: - $gutter;
                background-size: 280%;
            }
        }

        p {
            margin-bottom: $gutter / 2;
        }

        .col-1 {
            width: auto;
            flex: 1.5;
            padding: $gutter * 2 $gutter * 2 $gutter * 2 $gutter * 2;

            @media #{$media-bx} {
                width: 50%;
                flex: auto;
            }

            @media #{$media-m} {
                padding: 0 $gutter * 2 $gutter * 2 $gutter;
            }

            @media #{$media-sm} {
                width: 100%;
                padding: 0 $gutter * 2 $gutter $gutter;
            }

            @media #{$media-sx} {
                padding: 0 $gutter / 2;
            }

            h1 {
                font-family: $f-family-menu;
                font-weight: 700;
                margin-bottom: $gutter / 4;
            }
        }

        .col-2 {
            width: auto;
            flex: 1;

            @media #{$media-bx} {
                width: 50%;
                flex: auto;
            }

            @media #{$media-sm} {
                width: 100%;
                display: flex;
                justify-content: center;
            }

            @media #{$media-sx} {
                padding: 0 $gutter / 2;
            }

            figure {
                width: max-content;
                max-width: 750px;
            }
        }

        .col-3 {
            width: auto;
            flex: 1;
            padding: 0 $gutter / 2 0 $gutter;
            align-self: flex-end;

            @media #{$media-bx} {
                width: 100%;
                flex: auto;
                padding: $gutter $gutter / 2 0 $gutter * 2;
            }

            @media #{$media-m} {
                padding: $gutter $gutter / 2 0 $gutter;
            }

            @media #{$media-sx} {
                padding: $gutter $gutter / 2;
            }

            h2 {
                font-family: $f-family-menu;
                font-weight: 700;
                margin-bottom: $gutter / 4;
            }
        }

        .col-4 {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            gap: 40px;
            margin-top: $gutter * 8;
            padding: 0 $gutter / 2;

            @media #{$media-bx} {
                margin-top: $gutter * 4;
                padding: 0 $gutter * 2;
            }

            @media #{$media-b} {
                margin-top: $gutter;
            }

            @media #{$media-m} {
                padding: 0 $gutter;
            }

            @media #{$media-sx} {
                padding: 0 $gutter / 2;
                margin-top: 0;
            }

            .box {
                width: auto;
                flex: 1;

                @media #{$media-sm} {
                    width: 46%;
                    flex: auto;
                }

                .image {
                    position: relative;
                    overflow: hidden;
                    line-height: 0;

                    &::before {
                        position: absolute;
                        content: '';
                        width: 100%;
                        height: 100%;
                        z-index: 2;
                        pointer-events: none;
                    }

                    img {
                        transition: $trans-default;

                        &:hover {
                            transform: scale(1.2);
                        }
                    }
                }

                /* &.box_0 {
                    .image {
                        &::before {
                            background: url('../images/V_small.svg') no-repeat top right / contain;
                        }
                    }
                }

                &.box_1 {
                    .image {
                        &::before {
                            background: url('../images/V_small.svg') no-repeat top left / contain;
                        }
                    }
                }

                &.box_2 {
                    .image {
                        &::before {
                            background: url('../images/V_small.svg') no-repeat top left / contain;
                            transform: rotate(-90deg);
                            transform-origin: 19% 54%;
                        }
                    }
                } */

                h4 {
                    font-size: 2.6rem;
                    font-weight: 700;
                    padding: $gutter / 2;

                    @media #{$media-bx} {
                        padding: $gutter / 2 0;
                        font-size: 2.4rem;
                    }

                    @media #{$media-b} {
                        font-size: 2.2rem;
                    }

                    @media #{$media-sx} {
                        padding: $gutter / 4 0;
                    }
                }

                p {
                    padding: 0 $gutter / 2;

                    @media #{$media-bx} {
                        padding: 0;
                        font-size: 1.8rem;
                    }

                    @media #{$media-sx} {
                        font-size: 1.6rem;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    //.home__company

    .home__news {
        display: flex;
        flex-direction: column;
        margin: $gutter * 4 0 $gutter 0;
        padding: 0 $gutter / 2;

        @media #{$media-m} {
            padding: 0 $gutter;
            margin: $gutter * 2 0 0 0;
        }

        @media #{$media-sx} {
            padding: 0 $gutter / 2;
            margin-top: $gutter;
        }

        h2 {
            font-family: $f-family-menu;
            font-size: 4rem;
            margin-bottom: $gutter * 1.5;

            @media #{$media-m} {
                font-size: 3.6rem;
                margin-bottom: $gutter;
            }

            @media #{$media-s} {
                font-size: 3rem;
            }

            @media #{$media-sx} {
                font-size: 2.6rem;
            }
        }

        .items {
            display: flex;
            flex-wrap: wrap;
            gap: 40px;

            @media #{$media-bx} {
                overflow-x: auto;
                flex-wrap: nowrap;
            }

            article {
                width: auto;
                flex: 1;

                @media #{$media-bx} {
                    flex: auto;
                    min-width: 40vw;
                }

                @media #{$media-m} {
                    min-width: 50vw;
                }

                @media #{$media-s} {
                    min-width: 70vw;
                }

                .content {
                    display: flex;
                    flex-direction: column;
                    border-top: 1px solid var(--c-black);
                    padding: $gutter / 2;

                    @media #{$media-sm} {
                        padding-left: 0;
                    }

                    time {
                        font-size: 2rem;

                        @media #{$media-m} {
                            font-size: 1.8rem;
                        }

                        @media #{$media-sx} {
                            font-size: 1.6rem;
                        }
                    }

                    .title {
                        min-height: $gutter * 4;
                        font-size: 2.2rem;
                        font-weight: 700;
                        margin-top: $gutter / 2;

                        @media #{$media-bx} {
                            min-height: 0;
                            margin-bottom: $gutter / 2;
                        }

                        @media #{$media-m} {
                            font-size: 2rem;
                        }

                        @media #{$media-sx} {
                            font-size: 1.6rem;
                        }
                    }

                    a:not(.link) {
                        text-transform: uppercase;
                        display: flex;
                        align-items: center;
                        text-decoration: underline;
                        font-size: 1.8rem;
                        font-weight: 500;
                        font-family: $f-family-menu;

                        @media #{$media-m} {
                            font-size: 1.6rem;
                        }

                        svg {
                            width: 23px;
                            height: 17px;
                            margin-left: $gutter / 4;

                            path {
                                stroke: var(--c-corporate-1);
                            }
                        }
                    }
                }
            }
        }
    }

    // .home__news

}

// .page-template-homepage