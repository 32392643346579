// Century Gothic
@font-face {
    font-family: 'Century Gothic';
    src: url('../fonts/CenturyGothic.woff2') format('woff2'),
        url('../fonts/CenturyGothic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Century Gothic';
    src: url('../fonts/CenturyGothic-Bold.woff2') format('woff2'),
        url('../fonts/CenturyGothic-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

// Lato
@font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato-Light.woff2') format('woff2'),
        url('../fonts/Lato-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato-Regular.woff2') format('woff2'),
        url('../fonts/Lato-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato-Medium.woff2') format('woff2'),
        url('../fonts/Lato-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato-Semibold.woff2') format('woff2'),
        url('../fonts/Lato-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato-Bold.woff2') format('woff2'),
        url('../fonts/Lato-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato-Black.woff2') format('woff2'),
        url('../fonts/Lato-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}