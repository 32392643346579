/*------------------------------------*\
    Footer
   #footer.footer
\*------------------------------------*/

.footer {
    position: relative;
    z-index: 0;

    @media #{$media-m} {
        margin-top: $gutter*2;
    }

    @media #{$media-sx} {
        margin-top: $gutter;
    }

    section {
        @media #{$media-uw} {
            width: $w-media-uw;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .footer__newsletter_company_location {
        display: flex;
        flex-wrap: wrap;
        margin-top: $gutter;
        margin-bottom: $gutter;
        padding: 0 $gutter / 2;
        gap: 40px;
        font-family: $f-family-menu;
        background: url('../images/forma_footer.svg') no-repeat center right;

        @media #{$media-bx} {
            padding: 0 $gutter;
        }

        @media #{$media-sm} {
            margin-top: 0;
        }

        @media #{$media-sx} {
            padding: 0 $gutter / 2;
        }

        .item {
            width: auto;
            flex: 1;

            @media #{$media-m} {
                flex: 2;
            }

            @media #{$media-sm} {
                flex: auto;
            }
        }

        .cont_block {
            flex: 2;
            display: flex;

            @media #{$media-b} {
                flex-direction: column;
                gap: 10px;
            }
        }

        .newsletter {
            .title {
                font-size: 3rem;
                line-height: 1.3;
                font-family: $f-family-menu;
                margin-bottom: $gutter / 2;
                display: inline-block;

                @media #{$media-bx} {
                    font-size: 2.6rem;
                }

                @media #{$media-sx} {
                    font-size: 2.4rem;
                }
            }

            p {
                font-size: 1.8rem;

                @media #{$media-sx} {
                    font-size: 1.6rem;
                }
            }

            .btn--newsletter {
                background-color: var(--c-corporate-5);
                color: var(--c-white);
                font-size: 2.2rem;
                font-weight: 500;
                text-transform: uppercase;
                gap: 10px;
                padding: 1rem 3rem 1rem;
                margin-top: $gutter;
                transition: $trans-default;

                &:hover {
                    background-color: var(--c-corporate-1);
                }
            }
        }

        .telefono_email {
            display: flex;
            flex-direction: column;
            padding-left: $gutter;

            @media #{$media-sm} {
                padding-left: 0;
            }

            .box {
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                gap: 40px;
                margin-bottom: $gutter / 2;

                @media #{$media-bx} {
                    gap: 20px;
                }

                .icona {
                    border-radius: 50%;
                    background-color: var(--c-corporate-5);
                    width: 60px;
                    height: 60px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    @media #{$media-sx} {
                        width: 50px;
                        height: 50px;
                    }
                }

                .content {
                    display: flex;
                    flex-direction: column;
                    font-size: 2.1rem;
                    font-weight: 500;

                    @media #{$media-bx} {
                        font-size: 1.8rem;
                    }

                    @media #{$media-sx} {
                        font-size: 1.4rem;
                    }

                    .big {
                        font-size: 3rem;
                        line-height: 1;

                        @media #{$media-bx} {
                           font-size: 2.6rem;
                        }

                        @media #{$media-sx} {
                            font-size: 2.1rem;
                        }
                    }
                }
            }
        }

        .location {
            display: flex;
            flex-direction: column;
            padding-left: $gutter;

            @media #{$media-bx} {
                padding-left: 0;
            }

            @media #{$media-b} {
                padding-left: $gutter;
            }

            @media #{$media-sm} {
                padding-left: 0;
            }

            .box {
                display: flex;
                flex-wrap: nowrap;
                gap: 40px;
                margin-bottom: $gutter / 2;

                @media #{$media-bx} {
                    gap: 20px;
                }

                .icona {
                    border-radius: 50%;
                    background-color: var(--c-corporate-5);
                    width: 60px;
                    height: 60px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex: 0 0 auto;

                    @media #{$media-sx} {
                        width: 50px;
                        height: 50px;
                    }
                }

                .content {
                    display: flex;
                    flex-direction: column;
                    font-size: 2.1rem;
                    font-weight: 500;

                    @media #{$media-bx} {
                        font-size: 1.8rem;
                    }

                    .indirizzo {
                        display: flex;
                        flex-direction: column;
                        padding-bottom: $gutter / 2;
                        border-bottom: 1px solid var(--c-black);
                        margin-bottom: $gutter / 2;

                        a {
                            font-size: 1.6rem;
                            font-style: italic;
                            margin-top: $gutter / 3;
                            display: flex;
                            align-items: center;
                            gap: 10px;

                            @media #{$media-sx} {
                                font-size: 1.4rem;
                            }
                        }
                    }
                }
            }
        }
    }

    // .footer__newsletter_company_location

    .footer_socials_menu {
        width: 100%;
        background-color: var(--c-corporate-3);

        .content {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: $gutter * 1.5 $gutter $gutter * 3 $gutter;

            @media #{$media-uw} {
                width: $w-media-uw;
                margin-left: auto;
                margin-right: auto;
            }

            @media #{$media-m} {
                flex-direction: column;
                align-items: center;
                padding-bottom: $gutter;
            }

            @media #{$media-sx} {
                padding: $gutter $gutter / 2;
            }

            .logo_socials {
                width: 25%;
                display: flex;
                flex-direction: column;
                align-items: center;

                @media #{$media-b} {
                    width: 35%;
                }

                @media #{$media-m} {
                    width: 100%;
                    margin-bottom: $gutter;
                }

                h1 {
                    max-width: 40rem;
                    width: 100%;

                    @media #{$media-sx} {
                        max-width: 28rem;
                    }

                    a {
                        svg {
                            width: 100%;
                            height: auto;

                            path {
                                fill: var(--c-white);
                            }
                        }
                    }
                }

                .socials {
                    margin-top: $gutter / 2;
                    display: flex;
                    gap: 15px;

                    a {
                        svg {

                            g,
                            path {
                                transition: $trans-default;
                            }

                            &:hover {
                                g {
                                    stroke: var(--c-black);
                                }

                                path {
                                    fill: var(--c-black);
                                }
                            }
                        }
                    }
                }
            }

            .footer_menu {
                width: auto;
                display: flex;
                justify-content: flex-end;
                margin-right: $gutter * 2;

                @media #{$media-s} {
                    width: 100%;
                    justify-content: center;
                    margin-right: 0;
                }

                .menu {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 200px;
                    font-family: $f-family-menu;

                    @media #{$media-m} {
                        gap: 100px;
                    }

                    @media #{$media-sx} {
                        gap: 30px;
                    }

                    li {
                        a {
                            font-size: 2.6rem;
                            font-weight: 700;
                            pointer-events: none;

                            @media #{$media-sx} {
                                font-size: 1.8rem;
                            }
                        }

                        .sub-menu {
                            margin-left: $gutter / 2;
                            margin-top: $gutter / 4;

                            li {
                                margin-bottom: $gutter / 6;

                                a {
                                    font-size: 2rem;
                                    font-weight: 400;
                                    pointer-events: all;
                                    transition: $trans-default;

                                    @media #{$media-sx} {
                                        font-size: 1.6rem;
                                    }

                                    &:hover {
                                        color: var(--c-accent);
                                    }
                                }
                            }


                        }
                    }
                }
            }
        }
    }

    // .footer_socials_menu

    .footer__policies {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        padding: $gutter * 2 $gutter / 2;
        font-family: $f-family-menu;

        @media #{$media-bx} {
            padding: $gutter $gutter / 2;
        }

        @media #{$media-m} {
            flex-direction: column;
        }

        @media #{$media-sx} {
            text-align: center;
            padding-top: $gutter / 2;
        }

        .copy {
            @media #{$media-sx} {
                font-size: 1.4rem;
            }
        }

        .items {
            display: flex;
            flex-wrap: wrap;
            gap: 50px;

            @media #{$media-m} {
                order: -1;
                padding: $gutter / 2 0;
            }

            @media #{$media-sx} {
                gap: 10px;
                flex-direction: column;
            }

            .item {
                text-transform: uppercase;
            }
        }
    }
    // .footer__policies


}

// .footer