/*------------------------------------*\
    Page Generico e legal: Template base
    page-content.php legal-content.php
    .page-template-default .page-template-legal
\*------------------------------------*/

.page-template-default,
.page-template-legal {

    .main {
        overflow-x: hidden;
        overflow-y: clip;
    }

    .post--generico {
        display: flex;
        flex-wrap: wrap;
        position: relative;

        .col-1 {
            width: 100%;
            padding: $gutter * 1.5 $gutter * 1.5 $gutter $gutter * 1.5;
            background: url('../images/V_white.svg') no-repeat top left / 557px 908px;

            @media #{$media-m} {
                padding-top: $gutter / 2;
            }

            @media #{$media-sm} {
                padding: $gutter;
            }

            @media #{$media-sx} {
                padding: $gutter / 2;
            }
        }

        h1 {
            font-size: 2.6rem;

            @media #{$media-bx} {
                font-size: 2.2rem;
            }

            @media #{$media-sx} {
                font-size: 1.8rem;
            }
        }

        h2 {
            font-size: 4rem;
            font-weight: 700;
            line-height: 1.3;
            margin-top: $gutter / 2;
            max-width: 60%;

            @media #{$media-bx} {
                font-size: 3.2rem;
                margin-top: $gutter / 4;
                max-width: none;
            }

            @media #{$media-sm} {
                font-size: 2.6rem;
            }

            @media #{$media-sx} {
                font-size: 2.3rem;
                line-height: 1.4;
            }
        }

        .text {
            margin-top: $gutter;
            font-size: 1.8rem;
            font-family: $f-family-menu;
            max-width: 80%;

            @media #{$media-sx} {
                font-size: 1.6rem;
                max-width: 100%;
            }
        }

        #iubenda_policy.iubenda_fluid_policy .iub_container {
            margin-top: 0;
        }

        #iubenda_policy .iub_content {
            padding: 0;
        }

    }

    //.post--generico
}

//.page-template-default 

// stili generici usati nelle pagine di attivazione utente o recupera password
.template__generico {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: $gutter * 1.5 $gutter * 1.5;

    @media #{$media-sm} {
        padding: $gutter;
    }

    @media #{$media-sx} {
        padding: $gutter / 2;
        min-height: auto;
    }

    h1 {
        font-size: 2.6rem;

        @media #{$media-bx} {
            font-size: 2.2rem;
        }

        @media #{$media-sx} {
            font-size: 1.8rem;
        }
    }

    h2 {
        font-size: 4rem;
        font-weight: 700;
        line-height: 1.3;
        margin-top: $gutter / 2;
        margin-bottom: $gutter;
        color: var(--c-accent);

        @media #{$media-bx} {
            font-size: 3.2rem;
            margin-top: $gutter / 4;
            margin-bottom: $gutter / 2;
        }

        @media #{$media-b} {
            width: 100%;
            max-width: none;
        }

        @media #{$media-sx} {
            font-size: 2.3rem;
            line-height: 1.4;
        }
    }

    p {
        font-size: 2rem;
        font-family: $f-family-menu;
        line-height: 1.4;

        @media #{$media-bx} {
            font-size: 1.8rem;
        }

        @media #{$media-sx} {
            font-size: 1.6rem;
        }
    }
}