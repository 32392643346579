/*------------------------------------*\
    Page Template: Richiedi informazioni auto
    richiedi-informazioni.php
    .page-template-richiedi-informazioni
\*------------------------------------*/

.page-template-richiedi-informazioni {

    .richiedi_informazioni_auto__header {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        width: 100%;
        padding: $gutter * 1.5 $gutter * 1.5 $gutter $gutter * 1.5;

        @media #{$media-m} {
            padding-top: $gutter / 2;
        }

        @media #{$media-sm} {
            padding: $gutter;
        }

        @media #{$media-sx} {
            padding: $gutter / 2;
        }

        .col-1 {
            width: 100%;
            display: flex;
            flex-direction: column;

            h1 {
                font-size: 2.6rem;

                @media #{$media-bx} {
                    font-size: 2.2rem;
                }

                @media #{$media-sx} {
                    font-size: 1.8rem;
                }
            }

            h2 {
                font-size: 4rem;
                font-weight: 700;
                line-height: 1.3;
                margin-top: $gutter / 2;
                margin-bottom: $gutter * 1.5;

                @media #{$media-bx} {
                    font-size: 3.2rem;
                    margin-top: $gutter / 4;
                    margin-bottom: $gutter;
                }

                @media #{$media-sx} {
                    font-size: 2.3rem;
                    line-height: 1.4;
                    margin-bottom: $gutter / 4;
                }
            }

            p {
                font-size: 2rem;
                font-family: $f-family-menu;
                margin-bottom: $gutter / 2;
                max-width: 50%;

                @media #{$media-bx} {
                    font-size: 1.8rem;
                }

                @media #{$media-b} {
                    max-width: 60%;
                }

                @media #{$media-m} {
                    max-width: 70%;
                }

                @media #{$media-sm} {
                    max-width: none;
                }

                @media #{$media-sx} {
                    font-size: 1.6rem;
                    margin-bottom: 0;
                }
            }
        }

        //.col-1

        .col-2 {
            width: 25%;

            @media #{$media-m} {
                width: 35%;
            }

            @media #{$media-sm} {
                width: 100%;
            }

            h3 {
                font-size: 3rem;
                font-weight: 700;
                margin-top: $gutter / 2;

                @media #{$media-bx} {
                    font-size: 2.6rem;
                }

                @media #{$media-sx} {
                    font-size: 2.4rem;
                }
            }

            p {
                font-size: 2.2rem;
                margin-top: $gutter / 2;

                @media #{$media-bx} {
                    font-size: 2rem;
                }

                @media #{$media-sx} {
                    font-size: 1.8rem;
                    margin-top: 0;
                    margin-bottom: $gutter / 4;
                }
            }

            h5 {
                font-family: $f-family-menu;
                font-size: 2.4rem;
                font-weight: 900;
                color: var(--c-corporate-5);

                @media #{$media-bx} {
                    font-size: 2.2rem;
                }

                @media #{$media-sx} {
                    font-size: 1.8rem;
                }
            }
        }
        //.col-2

        .col-3 {
            width: 60%;

            @media #{$media-bx} {
                width: 70%;
            }

            @media #{$media-m} {
                width: 60%;
            }

            @media #{$media-sm} {
                width: 100%;
            }

            .formRichiediInformazioniAuto {
                background-color: var(--c-corporate-3);
                padding: $gutter / 2 $gutter * 3;

                @media #{$media-b} {
                    padding: $gutter / 2 $gutter * 2;
                }

                @media #{$media-m} {
                    padding: $gutter / 2 $gutter;
                }

                @media #{$media-sx} {
                    padding: $gutter / 2;
                }

                .titoloForm {
                    text-align: center;
                }
            }
        }
        //.col-3

    }
    // .richiedi_informazioni_auto__header
}

// .page-template-richiedi-informazioni