.facewp-bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: $gutter;

    .facetwp-page {
        padding: $gutter / 4 $gutter / 2;
        border: 2px solid var(--c-black);
        transition: $trans-default;

        &.active {
            background-color: var(--c-corporate-5);
            border: 2px solid var(--c-corporate-5);
            color: var(--c-white);
        }

        &:hover {
            background-color: var(--c-corporate-5);
            border: 2px solid var(--c-corporate-5);
            color: var(--c-white);
        }
    }
}