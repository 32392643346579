/*------------------------------------*\
    Page Template: Prenota Test Drive
    prenota-test-drive.php
    .page-template-prenota-test-drive
\*------------------------------------*/

.page-template-prenota-test-drive {

    .prenota-test-drive__header {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        padding: $gutter * 1.5 $gutter * 1.5 $gutter $gutter * 1.5;

        @media #{$media-m} {
            padding-top: $gutter / 2;
        }

        @media #{$media-sm} {
            padding: $gutter;
        }

        @media #{$media-sx} {
            padding: $gutter / 2;
        }

        .col-1 {
            width: 100%;
            display: flex;
            flex-direction: column;

            h1 {
                font-size: 2.6rem;

                @media #{$media-bx} {
                    font-size: 2.2rem;
                }

                @media #{$media-sx} {
                    font-size: 1.8rem;
                }
            }

            h2 {
                font-size: 4rem;
                font-weight: 700;
                line-height: 1.3;
                margin-top: $gutter / 2;
                margin-bottom: $gutter * 1.5;

                @media #{$media-bx} {
                    font-size: 3.2rem;
                    margin-top: $gutter / 4;
                    margin-bottom: $gutter;
                }

                @media #{$media-sx} {
                    font-size: 2.3rem;
                    line-height: 1.4;
                    margin-bottom: $gutter / 4;
                }
            }

            p {
                font-size: 2rem;
                font-family: $f-family-menu;
                margin-bottom: $gutter / 2;
                max-width: 50%;

                @media #{$media-bx} {
                    font-size: 1.8rem;
                }

                @media #{$media-b} {
                    max-width: 60%;
                }

                @media #{$media-m} {
                    max-width: 70%;
                }

                @media #{$media-sm} {
                    max-width: none;
                }

                @media #{$media-sx} {
                    font-size: 1.6rem;
                    margin-bottom: 0;
                }
            }
        }

        //.col-1

        .col-2 {
            width: 100%;
            display: flex;
            justify-content: center;

            .content {
                background-color: var(--c-corporate-3);
                padding: $gutter;
                display: flex;
                flex-direction: column;
                align-items: center;

                @media #{$media-sx} {
                    padding: $gutter / 2;
                }

                h3 {
                    font-size: 3rem;
                    font-weight: 700;
                    display: flex;
                    justify-content: center;
                    margin-bottom: $gutter / 2;

                    @media #{$media-s} {
                        font-size: 2.6rem;
                    }

                    @media #{$media-sx} {
                        font-size: 2.2rem;
                        margin-bottom: $gutter / 4;
                    }
                }

                .top {
                    display: flex;
                    gap: 30px;
                    margin-bottom: $gutter / 2;
                    width: 100%;

                    @media #{$media-s} {
                        flex-direction: column;
                    }

                    figure {
                        max-width: 40%;

                        @media #{$media-s} {
                            max-width: 100%;
                        }
                    }

                    h4 {
                        font-weight: 700;
                        font-size: 2.4rem;
                        text-transform: uppercase;

                        @media #{$media-s} {
                            order: -1;
                            text-align: center;
                        }

                        @media #{$media-sx} {
                            font-size: 2.2rem;
                        }
                    }
                }

                .gform_wrapper {
                    display: flex;
                    justify-content: center;

                    .formPrenotaTestDrive {
                        width: 85%;

                        @media #{$media-sm} {
                            width: 100%;
                        }

                        h3 {
                            margin-bottom: 0;
                        }
                    }
                }

                
            }
        }
        //.col-2

    }
    // .prenota-test-drive__header
}

// .page-template-prenota-test-drive