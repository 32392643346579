/*------------------------------------*\
    Page Template: Nuovo
    nuovo.php
    .page-template-nuovo
\*------------------------------------*/

.page-template-nuovo {
    .main {
        overflow-x: hidden;
        overflow-y: clip;
    }

    .v_bg {
        width: 35vw;
        height: calc(100vh - var(--h-header));
        min-width: 800px;
        position: absolute;
        top: 0;
        right: -28rem;
        pointer-events: none;
        z-index: -1;
        overflow: hidden;
        display: flex;

        svg {
            width: 100%;
            height: auto;
        }
    }

    .nuovo__intro {
        display: flex;
        position: relative;
        flex-direction: column;
        width: 100%;
        min-height: calc(100vh - var(--h-header));
        padding: $gutter * 2 0 $gutter 0;

        @media #{$media-s} {
            padding: $gutter 0 $gutter 0;
        }

        @media #{$media-sx} {
            padding: $gutter / 2 0 0 0;
        }

        .intro {
            display: flex;
            gap: 40px;
            padding-left: $gutter * 2;

            @media #{$media-sm} {
                padding-left: $gutter;
            }

            @media #{$media-s} {
                flex-direction: column;
                align-items: center;
                gap: 20px;
                text-align: center;
                padding-right: $gutter;
            }

            @media #{$media-sx} {
                padding-right: $gutter / 2;
                padding-left: $gutter / 2;
            }

            .col-1 {
                display: flex;
                align-items: center;

                .image {
                    width: 114px;
                    height: 96px;

                    @media #{$media-sx} {
                        width: 90px;
                        height: auto;
                    }

                    svg {
                        width: 100%;
                        height: auto;

                        path {
                            fill: var(--c-corporate-5);
                        }
                    }
                }
            }

            .col-2 {
                display: flex;
                flex-direction: column;
                justify-content: center;
                max-width: 50vw;

                @media #{$media-bx} {
                    max-width: 60vw;
                }

                @media #{$media-b} {
                    max-width: 70vw;
                }

                @media #{$media-m} {
                    max-width: 90vw;
                }

                h1 {
                    font-size: 2.4rem;
                    margin-bottom: $gutter / 2;

                    @media #{$media-s} {
                        font-size: 2.2rem;
                    }

                    @media #{$media-sx} {
                        font-size: 2rem;
                    }
                }

                p {
                    font-size: 2.2rem;

                    @media #{$media-s} {
                        font-size: 2rem;
                    }

                    @media #{$media-sx} {
                        font-size: 1.8rem;
                    }
                }
            }
        }

        //.intro

        .elenco_modelli {
            padding: $gutter * 2 0 $gutter * 2 $gutter * 2;

            @media #{$media-bx} {
                padding: $gutter * 2 0 $gutter * 2 0;
            }

            @media #{$media-m} {
                padding: $gutter 0 $gutter $gutter / 2;
            }

            @media #{$media-sx} {
                padding: $gutter / 2 0 $gutter $gutter / 2;
            }

            .sliderModelli {
                .item {
                    padding-bottom: $gutter;

                    .box_modello {
                        .image {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            min-height: $gutter * 5;
                            line-height: 0;

                            img {
                                transition: $trans-default;

                                &:hover {
                                    transform: scale(1.1);
                                }
                            }
                        }

                        .content {
                            text-align: center;

                            h1 {
                                font-size: 3.6rem;
                                font-weight: 700;
                            }

                            .prezzo {
                                font-family: $f-family-menu;

                                span {
                                    font-size: 1.4rem;

                                    &.big {
                                        font-size: 2.4rem;
                                        font-weight: 900;
                                        color: var(--c-accent);
                                        margin-left: $gutter / 6;
                                    }
                                }
                            }

                            .rateizzato {
                                font-family: $f-family-menu;

                                span {
                                    font-size: 1.4rem;

                                    &.rata {
                                        font-size: 1.8rem;
                                        color: var(--c-accent);
                                    }
                                }
                            }

                            a {
                                display: inline-flex;
                                align-items: center;
                                justify-content: center;
                                gap: 10px;
                                font-family: $f-family-menu;
                                font-size: 2rem;
                                font-weight: 500;
                                margin-top: $gutter / 4;
                                text-decoration: underline;

                                @media #{$media-b} {
                                    font-size: 1.8rem;
                                }

                                @media #{$media-m} {
                                    font-size: 1.6rem;
                                }

                                @media #{$media-sx} {
                                    font-size: 1.4rem;
                                }

                                svg {
                                    width: 29px;
                                    height: 24px;

                                    path {
                                        stroke: var(--c-corporate-1);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        // .elenco_modelli

        .nissan_prontaconsegna {

            padding: 0 $gutter / 2;

            @media #{$media-m} {
                padding: 0 $gutter;
            }

            @media #{$media-s} {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 0;
            }

            .btn--arrowdown {
                background-color: var(--c-corporate-5);
                color: var(--c-white);
                font-size: 2.2rem;
                font-weight: 500;
                text-transform: uppercase;
                gap: 10px;
                padding: 1rem 3rem 1rem;
                margin-top: $gutter / 4;
                transition: $trans-default;

                @media #{$media-m} {
                    font-size: 2rem;
                }

                @media #{$media-sx} {
                    font-size: 1.6rem;
                }

                &:hover {
                    background-color: var(--c-corporate-1);
                }

                svg {
                    transform: rotate(90deg);
                }
            }

            .content {
                background-color: var(--c-corporate-3);
                margin: $gutter $gutter * 4;
                padding: $gutter / 2 $gutter / 1.5 $gutter / 1.5 $gutter / 1.5;

                @media #{$media-bx} {
                    margin: $gutter $gutter * 2;
                }

                @media #{$media-b} {
                    margin: $gutter $gutter;
                }

                @media #{$media-m} {
                    margin: $gutter;
                }

                @media #{$media-s} {
                    margin: $gutter 0;
                }

                h2 {
                    font-size: 2.4rem;
                    margin-bottom: $gutter / 2;

                    @media #{$media-s} {
                        font-size: 2.2rem;
                    }

                    @media #{$media-sx} {
                        font-size: 2rem;
                    }
                }

                p {
                    font-size: 2.2rem;

                    @media #{$media-s} {
                        font-size: 2rem;
                    }

                    @media #{$media-sx} {
                        font-size: 1.8rem;
                    }
                }
            }
        }

        // .nissan_prontaconsegna
    }

    // .nuovo__intro

    .nuovo__elenco_auto {
        display: flex;
        flex-direction: column;

        .elenco_auto {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: 1fr;
            grid-column-gap: 100px;
            grid-row-gap: 100px;
            padding: 0 18rem;

            @media #{$media-u} {
                grid-column-gap: 40px;
                grid-row-gap: 80px;
            }

            @media #{$media-bx} {
                padding: 0 10rem;
                grid-column-gap: 5px;
                grid-row-gap: 60px;
            }

            @media #{$media-b} {
                padding: 0 6rem;
                grid-template-columns: repeat(2, 1fr);
                grid-column-gap: 50px;
                grid-row-gap: 50px;
            }

            @media #{$media-m} {
                padding: 0 8rem;
                grid-column-gap: 5px;
                grid-row-gap: 40px;
            }

            @media #{$media-sm} {
                grid-template-columns: repeat(1, 1fr);
                padding: 0 4rem;
            }

            @media #{$media-sx} {
                padding: 0 2rem;
            }

            .box_auto_elenco {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;

                .content {
                    padding: $gutter / 2 0;

                    @media #{$media-sx} {
                        padding-bottom: 0;
                    }

                    h6 {
                        text-transform: none;
                    }

                    .box_prezzo {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        gap: 15px;
                    }

                    .box_caratteristiche {
                        justify-content: space-around;

                        @media #{$media-sx} {
                            margin-top: $gutter / 2;
                        }
                    }

                    a {
                        text-decoration: none;
                        justify-content: center;
                        display: inline-flex;

                        @media #{$media-sx} {
                            margin-top: $gutter / 2;
                        }
                    }
                }

                .image {
                    position: relative;
                    width: 446px;
                    height: 339px;

                    @media #{$media-u} {
                        width: 370px;
                        height: 284px;
                    }

                    @media #{$media-bx} {
                        width: 360px;
                        height: 270px;
                    }

                    @media #{$media-b} {
                        width: 446px;
                        height: 339px;
                    }

                    @media #{$media-m} {
                        width: 330px;
                        height: 250px;
                    }

                    @media #{$media-sm} {
                        width: 446px;
                        height: 339px;
                    }

                    @media #{$media-s} {
                        width: 370px;
                        height: 284px;
                    }

                    @media #{$media-sx} {
                        width: 100%;
                        height: auto;
                        max-height: 300px;
                    }

                    .addToWishlist,
                    .wishlistNoLogin {
                        position: absolute;
                        right: $gutter / 4;
                        bottom: 0;
                    }
                }
            }
        }

    }

    // .nuovo__elenco_auto
}

// .page-template-nuovo 