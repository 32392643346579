/*------------------------------------*\
    Page Template: Login Registrazione
    login-registrazione.php
    .page-template-login-registrazione
\*------------------------------------*/

.page-template-login-registrazione {

    .main {
        overflow-x: hidden;
        overflow-y: clip;
    }

    .login__registrazione {
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: calc(100vh - var(--h-header));
        padding: $gutter * 1.5 $gutter * 1.5;

        @media #{$media-sm} {
            padding: $gutter;
        }

        @media #{$media-sx} {
            padding: $gutter / 2;
            min-height: auto;
        }

        .col-1 {
            width: 100%;

            h1 {
                font-size: 2.6rem;

                @media #{$media-bx} {
                    font-size: 2.2rem;
                }

                @media #{$media-sx} {
                    font-size: 1.8rem;
                }
            }

            h2 {
                font-size: 4rem;
                font-weight: 700;
                line-height: 1.3;
                margin-top: $gutter / 2;
                margin-bottom: $gutter;

                @media #{$media-bx} {
                    font-size: 3.2rem;
                    margin-top: $gutter / 4;
                    margin-bottom: $gutter / 2;
                }

                @media #{$media-b} {
                    width: 100%;
                    max-width: none;
                }

                @media #{$media-sx} {
                    font-size: 2.3rem;
                    line-height: 1.4;
                }
            }

            p {
                font-size: 2rem;
                font-family: $f-family-menu;
                line-height: 1.4;

                @media #{$media-bx} {
                    font-size: 1.8rem;
                }

                @media #{$media-sx} {
                    font-size: 1.6rem;
                }
            }
        }
        // .col-1

        .col-2 {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-top: $gutter;

            @media #{$media-sx} {
                margin-top: $gutter / 2;
            }

            &.dashboard {
                justify-content: flex-start;

                @media #{$media-sm} {
                    gap: 30px;
                }

                @media #{$media-sx} {
                    gap: 20px;
                }

                .wishlist, .profile {
                    display: flex;
                    border-radius: 5px;
                    width: 330px;
                    height: 164px;
                    background-color: #f5f5f5;
                    margin-right: $gutter;
                    -webkit-box-shadow: 0 0 1px 2px rgba(0,0,0,.1);
                    box-shadow: 0 0 3px 2px rgba(0,0,0,.1);
                    position: relative;
                    transition: $trans-default;

                    @media #{$media-m} {
                        width: 270px;
                        height: 135px;
                    }

                    @media #{$media-sm} {
                        width: 42%;
                        margin-right: 0;
                    }

                    @media #{$media-sx} {
                        width: 46%;
                        height: 110px;
                    }

                    &:hover {

                        &::before {
                            background-size: 40%;
                        }

                        a {
                            color: var(--c-accent);
                            letter-spacing: 0.2rem;
                        }
                    }

                    &::before {
                        position: absolute;
                        content: '';
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        pointer-events: none;
                        opacity: 0.1;
                        transition: $trans-default;
                    }
                    
                    a {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-family: $f-family-menu;
                        font-size: 4rem;
                        color: var(--c-corporate-2);
                        width: 100%;
                        height: 100%;
                        transition: $trans-default;

                        @media #{$media-m} {
                            font-size: 3.6rem;
                        }

                        @media #{$media-sx} {
                            font-size: 3rem;
                        }
                    }
                }

                .wishlist {
                    &::before {
                        background: url('../images/icon_wishlist.svg') no-repeat center center / 30%;

                        @media #{$media-sx} {
                            background-size: 50%;
                        }
                    }
                }

                .profile {
                    &::before {
                        background: url('../images/icon_login.svg') no-repeat center center / 30%;

                        @media #{$media-sx} {
                            background-size: 50%;
                        }
                    }
                }

                .logout {
                    width: 100%;
                    margin-top: $gutter / 2;
                    text-decoration: underline;
                    font-weight: 700;
                    transition: $trans-default;

                    @media #{$media-sx} {
                        margin-top: $gutter / 4;
                        font-size: 1.4rem;
                    }

                    &:hover {
                        color: var(--c-accent);
                    }
                }
            }

            .container_form {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 50vw;
                max-width: 900px;
                background-color: var(--c-corporate-3);
                padding: $gutter / 2 $gutter $gutter $gutter;
                margin-bottom: $gutter;

                @media #{$media-b} {
                    width: 60vw;
                }

                @media #{$media-m} {
                    width: 80vw;
                }

                @media #{$media-sm} {
                    width: 90vw;
                }

                @media #{$media-s} {
                    width: 100%;
                }

                @media #{$media-sx} {
                    padding: $gutter / 2;
                }

                h4 {
                    font-size: 3rem;
                    font-weight: 700;
                    margin-bottom: $gutter / 3;

                    @media #{$media-sx} {
                        font-size: 2.3rem;
                    }
                }

                .gf_login_form {
                    font-family: $f-family-menu;
                    width: 35%;
                    min-width: 300px;

                    @media #{$media-sx} {
                        width: 100%;
                        min-width: 0;
                    }
                }
                //. gf_login_form

                .gform_heading, .gfield_validation_message {
                    display: none;
                }

                .showError .gfield_validation_message, .user-email .gfield_validation_message {
                    display: block;
                }

                .recoverPassword_wrapper, .resetPassword_wrapper {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .recoverPassword {
                        width: 50%;

                        @media #{$media-m} {
                            width: 100%;
                        }
                    }

                    .resetPassword {
                        width: 80%;

                        @media #{$media-m} {
                            width: 100%;
                        }
                    }
                }

                .formRegistrazione .gform_body {
                    padding: 0 $gutter * 2;

                    @media #{$media-bx} {
                        padding: 0 $gutter;
                    }

                    @media #{$media-s} {
                        padding: 0;
                    }
                }

                .gfield_label {
                    font-weight: 400;
                    font-size: 1.8rem;

                    @media #{$media-sx} {
                        font-size: 1.6rem;
                    }
                }

                .gfield {
                    margin-top: 1rem;
                }

                .gfield--type-remember_me {
                    margin-top: 0;
                }

                .gform_footer button {
                    margin-top: 0;

                    @media #{$media-sx} {
                        font-size: 1.5rem;
                    }
                }

                .ginput_container_checkbox {
                    @media #{$media-sx} {
                        label {
                            font-size: 1.4rem;
                        }
                    }
                }

                .gf_login_links {
                    font-size: 1.2rem;
                    font-weight: 300;
                    width: 35%;
                    min-width: 300px;

                    a {
                        text-decoration: underline;
                    }
                }
                // .gf_login_links

                .gfield_radio {
                    display: flex;
                    gap: 20px;
                }

                .contatti_ok {
                    h1 {
                        font-size: 3rem;
                        font-weight: 700;

                        @media #{$media-sx} {
                            font-size: 2.4rem;
                            line-height: 1.3;
                        }
                    }

                    .image {
                        display: flex;
                        justify-content: center;
                    }
                }

                input[type="text"], input[type="password"], input[type="email"] {
                    background-color: var(--c-white);
                    border-radius: 10px;
                    border: 2px solid var(--c-grey-dark);
                }

                input[type="checkbox"], input[type="radio"] {
                    max-width: none;
                    width: 20px;
                    height: 20px;
                    margin-right: 0.5rem;
                }
            }
            // .container_form
        }
        // .col-2
    }
    // .login__registrazione
}

//.page-template-login-registrazione