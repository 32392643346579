/*------------------------------------*\
    Page Template: Usato / KM0
    usato.php km0.php
    .page-template-usato .page-template-km0
\*------------------------------------*/

.page-template-usato, .page-template-km0 {
    .main {
        overflow-x: hidden;
        overflow-y: clip;
    }

    .v_bg {
        width: 35vw;
        height: calc(100vh - var(--h-header));
        min-width: 800px;
        position: absolute;
        top: 0;
        right: -28rem;
        pointer-events: none;
        z-index: -1;
        overflow: hidden;
        display: flex;

        svg {
            width: 100%;
            height: auto;
        }
    }

    .usato__intro {
        display: flex;
        position: relative;
        flex-direction: column;
        width: 100%;
        padding: $gutter * 2 0 $gutter 0;

        @media #{$media-s} {
            padding: $gutter 0 $gutter 0;
        }

        @media #{$media-sx} {
            padding: $gutter / 2 0 0 0;
        }

        .intro {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            gap: 40px;
            padding-left: $gutter * 1.5;

            @media #{$media-sm} {
                padding-left: $gutter;
            }

            @media #{$media-s} {
                flex-direction: column;
                align-items: center;
                gap: 20px;
                text-align: center;
                padding: 0;
            }

            .col-1 {
                display: flex;
                align-items: center;
                width: 18%;

                @media #{$media-m} {
                    width: 100%;
                }

                @media #{$media-s} {
                    justify-content: center;
                }

                .image {
                    width: 250px;

                    @media #{$media-s} {
                        width: 200px;
                        height: auto;
                    }

                    @media #{$media-sx} {
                        width: 150px;
                    }

                    svg {
                        width: 100%;
                        height: auto;
                    }
                }
            }

            .col-2 {
                display: flex;
                flex-direction: column;
                justify-content: center;
                max-width: 68vw;

                @media #{$media-bx} {
                    max-width: 60vw;
                }

                @media #{$media-b} {
                    max-width: 70vw;
                }

                @media #{$media-m} {
                    max-width: 90vw;
                }

                h1 {
                    font-size: 2.4rem;
                    margin-bottom: $gutter / 2;

                    @media #{$media-s} {
                        font-size: 2.2rem;
                    }

                    @media #{$media-sx} {
                        font-size: 2rem;
                    }
                }

                p {
                    font-size: 2.2rem;

                    @media #{$media-s} {
                        font-size: 2rem;
                    }

                    @media #{$media-sx} {
                        font-size: 1.8rem;
                    }
                }
            }
        }

        //.intro

        .elenco_brands {
            padding: $gutter / 2 0 0 0;

            h4 {
                @media #{$media-sx} {
                   text-align: left;
                }
            }

            .sliderBrands {
                margin-top: $gutter / 4;
                display: flex;
                flex-wrap: wrap;

                @media #{$media-sx} {
                    overflow-x: auto;
                    flex-wrap: nowrap;
                }

                .item {
                    figure {
                        width: 100px;
                        margin-right: $gutter / 4;

                        @media #{$media-sx} {
                            width: 100px;
                        }

                        img {
                            transition: $trans-default;
                            border: 5px solid var(--c-white);

                            &:hover {
                                border: 5px solid var(--c-accent);
                            }
                        }
                    }

                    &.selected {
                        figure {
                            img {
                                border: 5px solid var(--c-accent);
                            }
                        }
                    }
                }
            }
        }

        // .elenco_brands
    }

    // .usato__intro

    .usato__elenco_auto {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: $gutter * 2;

        @media #{$media-sx} {
            margin-bottom: 0;
        }

        .col-1 {
            width: 20%;
            padding-left: $gutter / 2;

            @media #{$media-b} {
                width: 100%;
                padding: 0 6rem;
            }

            @media #{$media-s} {
                padding: 0 $gutter / 2;
            }

            @media #{$media-sx} {
                margin-top: $gutter;
            }

            .showFilters {
                display: none;

                @media #{$media-b} {
                    display: flex;
                }

                button {
                    font-size: 1.6rem;
                    text-transform: uppercase;
                    padding: 1rem 2rem;

                    img {
                        margin-right: $gutter / 2;
                        pointer-events: none;
                    }
                }
            }

            .elenco_filtri {
                background-color: var(--c-white);
                padding: $gutter / 2;

                @media #{$media-b} {
                    max-height: 0;
                    padding: 0 $gutter / 2;
                    overflow: hidden;
                    transition: $trans-default;

                    &.active {
                        max-height: none;
                        padding: $gutter / 2;
                        margin-top: $gutter / 2;
                    }
                }

                h4 {
                    font-size: 2.6rem;
                    font-family: $f-family-menu;
                    font-weight: 600;

                    @media #{$media-b} {
                        font-size: 2.4rem;
                    }

                    @media #{$media-s} {
                        font-size: 2.2rem;
                    }
                }

                .facets {
                    margin-top: $gutter / 2;
                    font-family: $f-family-menu;

                    details {
                        border-bottom: 1px solid var(--c-grey-normal);
                        padding: $gutter / 4 0;

                        summary {
                            position: relative;
                            cursor: pointer;
                            font-size: 2rem;

                            @media #{$media-s} {
                                font-size: 1.8rem;
                            }

                            &:after {
                                position: absolute;
                                content: '';
                                width: 100%;
                                height: 100%;
                                left: 0;
                                background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat right center;
                            }
                        }

                        .facetwp-type-checkboxes, .facetwp-type-radio {
                            display: flex;
                            flex-wrap: wrap;
                            margin-top: $gutter / 4;

                            &.facetwp-facet-modello {
                                .facetwp-checkbox {
                                    width: 100%;
                                }
                            }

                            &.facetwp-facet-neopatentati {
                                .facetwp-radio {
                                    width: 25%;
                                }
                            }

                            .facetwp-checkbox, .facetwp-radio {
                                flex: 1 0 auto;
                                width: 35%;
                                display: flex;
                                align-items: center;
                            }

                            .facetwp-overflow {
                                width: 100%;
                                display: flex;
                                flex-wrap: wrap;
                            }

                            .facetwp-toggle {
                                width: 100%;
                            }
                            
                        }

                        .facetwp-slider-wrap {
                            margin-top: $gutter / 2;
                        }

                        .facetwp-facet {
                            margin-bottom: 0;
                        }

                        .facetwp-counter, .facetwp-slider-reset {
                            display: none;
                        }

                        .facetwp-hidden {
                            display: none !important;
                        }
                    }

                    details[open] {
                        summary {
                            &:after {
                                transform: rotate(180deg);
                                right: -97%;
                                left: auto;
                            }
                        }
                    }
                }
            }
        }


        .col-2 {
            display: flex;
            flex-direction: column;
            width: 80%;

            @media #{$media-b} {
                width: 100%;
            }

            .totali_ordine {
                padding: $gutter / 2 0 $gutter 5rem;
                font-size: 1.8rem;
                display: flex;
                flex-direction: column;
                gap: 10px;

                @media #{$media-m} {
                    padding: $gutter / 2 0 $gutter 7rem;
                }

                @media #{$media-s} {
                    padding: 0 $gutter / 2;
                    margin-top: $gutter / 2;
                    margin-bottom: $gutter / 2;
                }

                strong {
                    margin-right: $gutter / 8;
                }

                .selections {
                    width: 100%;
                    
                    .facetwp-selection-label {
                        font-weight: 700;
                    }

                    .facetwp-selection-value {
                        color: var(--c-accent);
                    }
                }

                .results {
                    display: flex;
                    gap: 80px;

                    @media #{$media-s} {
                        gap: 10px;
                        justify-content: space-between;
                    }

                    @media #{$media-sx} {
                        flex-direction: column;
                    }

                    .paging {
                        display: flex;
                        align-items: center;

                        @media #{$media-s} {
                            font-size: 1.6rem;
                        }

                        .facetwp-facet {
                            margin-bottom: 0;
                        }
                    }

                    .ordine_per {
                        display: flex;
                        align-items: center;
                        gap: 15px;

                        @media #{$media-sm} {
                            span {
                                display: none;
                            }
                        }

                        .iconOrdinaPer {
                            display: none;

                            @media #{$media-sm} {
                                display: block;
                            }
                        }

                        .facetwp-facet-ordinaper {
                            margin-bottom: 0;
                        }

                        select {
                            font-family: $f-family-menu;
                            font-size: 1.4rem;
                            border-radius: 50px;
                            border: 0;
                            padding: 8px 12px;
                            appearance: none;
                            -webkit-appearance: none;
                            -moz-appearance: none;
                            cursor: pointer;
                            position: relative;
                            background: var(--c-corporate-3) url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat;
                            background-position: calc(100% - 0.75rem) center !important;
                        }
                    }
                }

            }

            .elenco_auto {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-template-rows: 1fr;
                grid-column-gap: 80px;
                grid-row-gap: 100px;
                padding: 0 5rem;

                @media #{$media-u} {
                    grid-column-gap: 40px;
                    grid-row-gap: 80px;
                }

                @media #{$media-bx} {
                    grid-column-gap: 60px;
                    grid-template-columns: repeat(2, 1fr);
                }

                @media #{$media-b} {
                    grid-column-gap: 40px;
                }

                @media #{$media-m} {
                    padding: 0 3rem;
                    grid-column-gap: 40px;
                    grid-row-gap: 60px;
                }

                @media #{$media-sm} {
                    grid-template-columns: repeat(1, 1fr);
                    padding: 0 4rem;
                }

                @media #{$media-s} {
                    padding: 0;
                    margin-top: $gutter / 2;
                }

                @media #{$media-s} {
                    padding: 0 $gutter / 2;
                }

                .box_auto_elenco {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    text-align: center;

                    .image {
                        width: 420px;
                        height: 283px;
                        position: relative;

                        @media #{$media-u} {
                            width: 370px;
                        }

                        @media #{$media-bx} {
                            width: 420px;
                        }

                        @media #{$media-b} {
                            width: 420px;
                        }

                        @media #{$media-sm} {
                            width: 390px;
                        }

                        @media #{$media-sx} {
                            width: 100%;
                            height: auto;
                        }

                        figure {
                            width: 100%;
                            height: 100%;
                        }

                        .addToWishlist, .wishlistNoLogin {
                            position: absolute;
                            right: 1rem;
                            bottom: 0rem;

                            @media #{$media-sx} {
                                //right: 6rem;
                            }
                        }

                        .prenotaTestDrive {
                            position: absolute;
                            right: 1rem;
                            bottom: 0rem;
                        }

                        .addToWishlist, .prenotaTestDrive {
                            transition: $trans-default;

                            @media #{$media-sx} {
                                width: 55px;
                                height: 55px;
                            }

                            &:hover {
                                transform: scale(1.1);
                            }
                        }
                    }

                    .content {
                        padding: $gutter / 2 0;

                        @media #{$media-sx} {
                            padding-bottom: 0;
                        }

                        h6 {
                            text-transform: none;
                        }

                        .box_prezzo {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            gap: 15px;
                        }

                        .box_caratteristiche {
                            justify-content: space-around;

                            @media #{$media-sx} {
                                margin-top: $gutter / 2;
                            }
                        }

                        a {
                            text-decoration: none;
                            justify-content: center;
                            display: inline-flex;
                            color: var(--c-white);

                            @media #{$media-sx} {
                                margin-top: $gutter / 2;
                            }
                        }
                    }
                }
            }

            // .elenco_auto
        }



    }

    // .nuovo__elenco_auto
}

// .page-template-usato .page-template-km0 