/*------------------------------------*\
   Text
\*------------------------------------*/

.text {

    a {
        color: currentColor;
    }

    ul,
    ol {
        margin: 2rem;
    }

    ol {
        list-style-type: decimal;
    }

    li {
        list-style-type: disc;
    }

    p+p {
        margin-top: $gutter * .5;
    }

    strong {
        font-weight: $f-weight-700;
    }

    em {
        font-style: italic;
    }

}

// .text





/*------------------------------------*\
    Box
    .box
\*------------------------------------*/

.box {}// .box









/*------------------------------------*\
    Title ( ex-box--intro )
    .box.box--title
\*------------------------------------*/

.box--title {

    margin: auto;
    margin-left: 0;
    padding: $gutter;

    max-width: 120rem;

    @media #{$media-sx} {
        padding: $gutter / 2;
    }

    .title {

        font-size: 2.2rem;
        font-weight: $f-weight-400;

        @media #{$media-m} { font-size: 2rem; }
        @media #{$media-s} { font-size: 1.8rem; }

    }// .title

    .title2 {

        color: var(--c-accent);
        // font-size: 8.3rem;
        font-size: 6.3rem;
        line-height: 1.2;
        font-weight: $f-weight-700;
        text-transform: uppercase;

        @media #{$media-m} { font-size: 6rem; }
        @media #{$media-s} { font-size: 4rem; }
        @media #{$media-sx} {
            font-size: 3.6rem;
            line-height: 1.1;
        }

    }// .title2

    .title3 {

        font-family: $f-family-text;
        font-size: 1.8rem;
        line-height: 1.6;

    }// .title3

    .text {

        margin-top: $gutter*.5;
        font-size: 1.6rem;
        line-height: 1.625;

    }// .text

}// .box--title





/*------------------------------------*\
    Intro
    .box.box--intro
\*------------------------------------*/

.box--intro {

    margin: auto;
    margin-left: 0;
    padding: $gutter;

    @media #{$media-sx} {
        padding: $gutter / 2 $gutter;
    }

    .title {

        font-size: 2.2rem;
        font-weight: $f-weight-400;

        @media #{$media-m} { font-size: 2rem; }
        @media #{$media-s} { font-size: 1.8rem; }

    }

    // .title

    .title2 {

        color: var(--c-accent);
        //font-size: 8.3rem;
        font-size: 6.3rem;
        line-height: 1.2;
        font-weight: $f-weight-700;
        text-transform: uppercase;

        @media #{$media-m} { font-size: 6rem; }
        @media #{$media-s} { font-size: 4rem; }

    }

    // .title2

	.title3 {}// .title3

    .text {

        font-size: 1.6rem;

    }

    // .text

}

// .box--intro


/*------------------------------------*\
    Title with icon
    .title--with-icon
\*------------------------------------*/

.title--with-icon {
    margin-bottom: $gutter / 2;
    padding-left: $gutter * 2.5;
    position: relative;
    display: flex;
    align-items: center;
    font-size: 4.2rem;
    line-height: 1.2;

    @media #{$media-bx} { 
        padding-left: $gutter * 2.2;
    }

    @media #{$media-b} { 
        font-size: 3rem;
        padding-left: $gutter * 2;
    }

    @media #{$media-sm} { 
        font-size: 2.8rem;
    }

    @media #{$media-sx} { 
        font-size: 2.4rem;
        padding-left: $gutter * 1.7;
    }

    &::before {
        content: '';
        position: absolute;
        left: 10px;
        width: 50px;
        height: 50px;
        background: url('../images/icona_home_uno.svg') no-repeat center center / contain;

        @media #{$media-sx} { 
            width: 40px;
            height: 40px;
            left: 0;
        }
    }

    &.reversed {
        padding-left: 0;
        padding-right: $gutter * 2.5;

        @media #{$media-bx} { 
            padding-right: $gutter * 2.2;
        }

        @media #{$media-b} { 
            padding-right: $gutter * 2;
        }

        @media #{$media-s} { 
            padding-right: 0;
            padding-left: $gutter * 2;
        }

        @media #{$media-sx} { 
            padding-left: $gutter * 1.7;
        }

        &::before {
            left: auto;
            right: 10px;

            @media #{$media-s} { 
                right: auto;
                left: 10px;
            }

            @media #{$media-sx} { 
                left: 0;
            }
        }
    }
}

/*------------------------------------*\
    Button --corporate-1
    .button--link
\*------------------------------------*/

.button--link {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: $gutter / 6 $gutter / 2;
    margin-top: $gutter;
    background-color: var(--c-corporate-1);
    color: var(--c-white);
    font-size: 1.8rem;
    transition: $trans-default;
    text-transform: uppercase;

    @media #{$media-sx} { 
        font-size: 1.4rem;
        padding: $gutter / 6 $gutter / 3;
        margin-top: $gutter / 2;
    }

    &:hover {
        background-color: var(--c-accent);
        color: var(--c-corporate-1);
    }
}

/*------------------------------------*\
    Button nero
\*------------------------------------*/

.btn {
    border-radius: 25px;
    padding: 1.5rem 3rem 1.2rem 3rem;
    line-height: 1.4;
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    transition: $trans-default;
    font-weight: 500;
    font-family: $f-family-menu;

    &:hover {
        background-color: var(--c-accent);
    }

    &.btn--black {
        background-color: var(--c-corporate-1);
        color: var(--c-grey-light);
        font-size: 1.8rem;
        text-transform: uppercase;
    }

    &.btn--vai {
        background-color: var(--c-corporate-5);
        color: var(--c-white);

        &:hover {
            background-color: var(--c-corporate-1);
        }

        svg {
            margin-left: $gutter / 2;
            
            path {
                stroke: var(--c-white);
            }
        }
    }

    &.btn--grey {
        background-color: var(--c-corporate-3);

        &:hover {
            background-color: var(--c-corporate-1);
            color: var(--c-white);
        }
    }

    &.btn--filtra {
        border: 0;
        outline: none;
        background-color: var(--c-corporate-5);
        color: var(--c-white);

        &:hover {
            background-color: var(--c-corporate-1);
        }
    }

}

/*------------------------------------*\
    Box prodotto in elenco
\*------------------------------------*/

.box_auto_elenco, .dati_auto_mobile {
    h5 {
        font-size: 1.4rem;

        @media #{$media-sx} {
            text-align: center;
        }
    }

    h6 {
        font-size: 2.6rem;
        font-weight: 700;
        text-transform: uppercase;
        margin-top: $gutter / 3;
        min-height: $gutter * 2;

        @media #{$media-m} {
            margin-bottom: $gutter / 2;
            font-size: 2.2rem;
        }

        @media #{$media-s} {
            min-height: 0;
        }

        @media #{$media-sx} {
            text-align: center;
            font-size: 2.1rem;
        }
    }

    .image {
        overflow: hidden;
        transition: $trans-default;
        border: 10px solid transparent;

        img {
            transition: $trans-default;
        }

        &:hover {
            border: 10px solid var(--c-corporate-5);

            img {
                transform: scale(1.1);
            }
        }
    }

    .box_prezzo {
        margin-top: $gutter / 1.5;
        font-size: 1.6rem;
        display: flex;
        align-items: flex-end;
        gap: 40px;

        @media #{$media-m} {
            margin-top: $gutter / 2;
        }

        .discountDaListino {
            display: flex;
            align-items: center;
            gap: 10px;
        }

        .big {
            font-size: 2.4rem;
            font-weight: 900;
            color: var(--c-corporate-5);
            line-height: 1.2;

            @media #{$media-m} {
                font-size: 2.2rem;
                line-height: 1.4;
            }

            &.discount {
                border: 3px solid var(--c-green);
                padding: $gutter / 8 $gutter / 4;
            }
        }

        .listino {
            text-decoration: line-through;
        }

        .bottom {
            display: flex;
            gap: 10px;

            .calcolaRata {
                text-decoration: underline;
                font-size: 1.4rem;
                cursor: pointer;
                transition: $trans-default;

                &:hover {
                    color: var(--c-accent);
                }
            }
        }
    }

    .box_caratteristiche {
        display: flex;
        flex-wrap: wrap;
        margin-top: $gutter;

        .box_single {
            width: 40%;
            display: flex;
            align-items: center;
            text-align: left;
            gap: 20px;
            font-size: 1.8rem;
            margin-bottom: $gutter / 2;

            @media #{$media-b} {
                font-size: 1.8rem;
            }

            @media #{$media-m} {
                font-size: 1.6rem;
            }

            @media #{$media-sx} {
                font-size: 1.4rem;
            }
        }
    }

    a {
        font-size: 2rem;
        font-weight: 500;
        text-decoration: underline;

        @media #{$media-b} {
            font-size: 1.8rem;
        }

        @media #{$media-m} {
            font-size: 1.6rem;
        }

        @media #{$media-sx} {
            font-size: 1.4rem;
        }

        svg {
            width: 29px;
            height: 24px;

            path {
                stroke: var(--c-corporate-1);
            }
        }

        &.btn {
            display: flex;
            align-items: center;
            margin-top: $gutter;
            gap: 20px;

            svg {
                path {
                    stroke: var(--c-white);
                }
            }
        }
    }
}

.dati_auto_mobile {
    display: none;

    @media #{$media-m} {
        display: flex;
        flex-direction: column;
    }
}